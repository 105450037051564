const yup = require('yup');

class Validation {
	constructor(props) {
		this.fieldTypes = props.fieldTypes;
	}
	setRequired(fieldOptions = {}, schema) {
		const schemaType = schema.describe().type;
		const { required, requiredWhen } = fieldOptions;
		if (!required && !requiredWhen) return schema;
		let requiredSchema;
		if (schemaType !== 'array') requiredSchema = schema.required();
		else requiredSchema = schema.required().min(1);
		if (required) return requiredSchema;
		return schema.when(`$${requiredWhen}`, (rule, schema) => {
			return rule === true ? requiredSchema : schema;
		});
	}
	buildSchemaFromFields(opts = {}) {
		const { fields = {}, models } = opts;
		const schema = {};
		Object.keys(fields).forEach(field => {
			const fieldDef = fields[field];
			if (!fieldDef) return;
			const { type, label } = fieldDef;
			const fieldType = this.fieldTypes[type];
			let fieldValidation = fieldType
				.validation(yup, {
					fieldDef,
					models,
					buildSchemaFromFields: this.buildSchemaFromFields.bind(this)
				})
				.label(label)
				.nullable();
			fieldValidation = this.setRequired(fieldDef.options, fieldValidation);
			schema[field] = fieldValidation;
		});
		return yup.object().shape(schema);
	}
}

module.exports = Validation;
