import _ from 'lodash';
import React from 'react';
import { Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TableInvoiceExportETA from '../components/TableInvoiceExportETA';
import { useUserContext } from '../contexts/userContext';
import { GenericModelTable } from '../components';

const StyledChip = withStyles({
	colorPrimary: {
		backgroundColor: '#2e7d32'
	},
	colorSecondary: {
		backgroundColor: '#d50000'
	}
})(Chip);

function columns(user) {
	const columns = [];
	if (user.role === 'admin') {
		columns.push(
			{
				name: 'invoiceStatus',
				label: 'Invoice Status',
				options: {
					rawValue: true,
					filter: true,
					sort: true,
					searchable: false,
					customFilterListOptions: {
						render: v => (v ? _.startCase(_.toLower(v)) : '')
					},
					filterOptions: {
						names: ['filed', 'not_filed'],
						renderValue: v => (v ? _.startCase(_.toLower(v)) : '')
					}
				},
				cellTemplate({ value }) {
					let color = 'primary';
					if (!value) return <></>;
					if (_.lowerCase(value) !== 'filed') color = 'secondary';
					return <StyledChip color={color} label={value}></StyledChip>;
				}
			},
			{
				name: 'etaInvoiceStatus',
				label: 'ETA Invoice Status',
				options: {
					rawValue: true,
					filter: true,
					sort: true,
					searchable: false,
					filterOptions: {
						names: ['Valid', 'Invalid', 'Rejected', 'Cancelled']
					}
				},
				cellTemplate({ value }) {
					let color = 'secondary';
					if (!value) return <></>;
					if (value === 'Valid') color = 'primary';
					if (value === 'Cancelled') color = 'default';
					return <StyledChip color={color} label={value}></StyledChip>;
				}
			},
			{
				name: 'receiptStatus',
				label: 'Receipt Status',
				options: {
					rawValue: true,
					filter: true,
					sort: true,
					searchable: false,
					customFilterListOptions: {
						render: v => (v ? _.startCase(_.toLower(v)) : '')
					},
					filterOptions: {
						names: ['filed', 'not_filed', 'returned'],
						renderValue: v => (v ? _.startCase(_.toLower(v)) : '')
					}
				},
				cellTemplate({ value }) {
					let color = 'primary';
					if (!value) return <></>;
					if (!['filed', 'returned'].includes(_.lowerCase(value)))
						color = 'secondary';
					return <StyledChip color={color} label={value}></StyledChip>;
				}
			},
			{
				name: 'etaReceiptStatus',
				label: 'ETA Receipt Status',
				options: {
					rawValue: true,
					filter: true,
					sort: true,
					searchable: false,
					customFilterListOptions: {
						render: v => (v ? _.startCase(_.toLower(v)) : '')
					},
					filterOptions: {
						names: ['filed', 'not_filed', 'returned'],
						renderValue: v => (v ? _.startCase(_.toLower(v)) : '')
					}
				},
				cellTemplate({ value }) {
					let color = 'primary';
					if (!value) return <></>;
					if (_.lowerCase(value) === 'not_filed') color = 'secondary';
					return <StyledChip color={color} label={value}></StyledChip>;
				}
			},
			{
				name: 'etaReturnReceiptStatus',
				label: 'ETA Return Receipt Status',
				options: {
					rawValue: true,
					filter: true,
					sort: true,
					searchable: false,
					customFilterListOptions: {
						render: v => (v ? _.startCase(_.toLower(v)) : '')
					},
					filterOptions: {
						names: ['filed', 'not_filed', 'returned'],
						renderValue: v => (v ? _.startCase(_.toLower(v)) : '')
					}
				},
				cellTemplate({ value }) {
					let color = 'primary';
					if (!value) return <></>;
					if (_.lowerCase(value) === 'not_filed') color = 'secondary';
					return <StyledChip color={color} label={value}></StyledChip>;
				}
			}
		);
	}
	columns.push(
		{
			name: 'storeId',
			label: 'Store',
			options: { filter: true, sort: true, searchable: false }
		},
		{
			name: 'invoiceNumber',
			label: 'Invoice Number',
			options: { filter: true, sort: true, searchable: true }
		},
		{
			name: 'partyType',
			label: 'Party Type',
			options: { sort: true, filter: true, searchable: false }
		},
		{
			name: 'systemParty',
			label: 'System Party',
			options: { sort: false, filter: true, searchable: false }
		},
		{
			name: 'partyId',
			label: 'Party',
			options: { sort: true, filter: true, searchable: false }
		},
		{
			name: 'partyName',
			label: 'Party Name',
			options: { sort: true, filter: true, searchable: true }
		},
		{
			name: 'totalPrice',
			label: 'Amount',
			options: { sort: true, filter: true, searchable: true }
		},
		{
			name: 'invoiceDate',
			label: 'Invoice Date',
			options: { sort: true, filter: true, searchable: true }
		},
		{
			name: 'createdDate',
			label: 'Created Date',
			options: { sort: true, filter: true, searchable: true }
		}
	);
	return columns;
}
const modelName = 'invoice';

function Invoices() {
	const { user } = useUserContext();
	const tableColumns = columns(user);
	const tableOptions = {
		modelName,
		modelNamePlural: 'Invoices',
		columns: tableColumns,
		tableOptions: {}
	};
	if (user.role === 'admin')
		tableOptions.tableOptions.customToolbar = function customToolbar() {
			return <TableInvoiceExportETA user={user} />;
		};

	return <GenericModelTable {...tableOptions} />;
}

export default Invoices;
