const modelName = 'user';

export default {
	model: modelName,
	elements: [
		{
			field: 'username'
		},
		{
			field: 'firstName'
		},
		{
			field: 'lastName'
		},
		{
			field: 'role'
		}
	]
};
