import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import InvoiceTableCell from './InvoiceTableCell';

function InvoiceTableRow({ deleteCell, columns, ...props }) {
	const cellProps = {
		lineIndex: props.lineIndex,
		invoiceLineOptions: props.invoiceLineOptions,
		record: props.record,
		readOnly: props.readOnly
	};
	return (
		<TableRow>
			{columns.map(column => (
				<InvoiceTableCell key={column.field} {...cellProps} {...column} />
			))}
			{deleteCell}
		</TableRow>
	);
}
export default InvoiceTableRow;
