import lodashPick from 'lodash/pick';
import childMultipleFieldType from '../../shared/field-types/child-multiple';

function getChildModelFields({ fieldDef, models }) {
	const { childModelName, childFields } = fieldDef.options;
	const childModelFields = models[childModelName].fields;
	const fields = lodashPick(childModelFields, childFields);
	return fields;
}

function getFormFields(fields) {
	const formFields = Object.keys(fields).map(fieldName => ({
		name: fieldName
	}));
	return formFields;
}

export default {
	...childMultipleFieldType,
	convertToDb(values = [], ctx) {
		const fields = getChildModelFields(ctx);
		const dbValues = values.map(record => {
			return ctx.convertToDbValues(record, fields);
		});
		return dbValues;
	},
	convertToForm(value, ctx) {
		const fields = getChildModelFields(ctx);
		const formFields = getFormFields(fields);
		if (!value) return [];
		const convertedValue = value.map(record =>
			ctx.convertToFormValues(record, formFields, fields)
		);
		return convertedValue;
	}
};
