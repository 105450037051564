import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContentText } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom ref={ref} {...props} />;
});

function AlertDialog({
	title = 'Alert!',
	children,
	open,
	setOpen,
	confirmationText = 'OK',
	cancellationText = null,
	onConfirm = () => {},
	confirmationOnClick = () => {
		setOpen(false);
		onConfirm();
	},
	confirmationColor = 'secondary',
	providingContent = false
}) {
	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			TransitionComponent={Transition}
			keepMounted
			disableBackdropClick
			disableEscapeKeyDown
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			{providingContent ? (
				children
			) : (
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{children}
					</DialogContentText>
				</DialogContent>
			)}
			<DialogActions>
				<Button
					color={confirmationColor}
					onClick={
						confirmationOnClick
							? confirmationOnClick
							: () => {
									setOpen(false);
									onConfirm();
							  }
					}
					style={{ textTransform: 'capitalize' }}
					variant="contained"
				>
					{confirmationText}
				</Button>
				{cancellationText && (
					<Button
						color="default"
						onClick={() => setOpen(false)}
						style={{ textTransform: 'capitalize' }}
						variant="text"
					>
						{cancellationText}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}
export default AlertDialog;
