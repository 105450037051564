const Big = require('big.js');

module.exports = {
	amount: {
		dependentFields: ['billLines'],
		formulate: ({ billLines = [] }) => {
			const totalAmount = billLines.reduce((total, billLine) => {
				const parsedAmount = parseFloat(billLine.amount);
				if (Number.isNaN(parsedAmount)) return total;
				return new Big(total).plus(parsedAmount).toNumber();
			}, 0);
			return totalAmount;
		}
	}
};
