import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Table } from '../components';

const columns = [
	{
		name: 'user_username',
		label: 'User',
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: 'modelName',
		label: 'Model Name',
		options: {
			filter: true,
			sort: true
		}
	},
	{
		name: 'action',
		label: 'Action',
		options: {
			filter: true,
			sort: true
		}
	},
	{
		name: 'timestamp',
		label: 'Timestamp',
		options: {
			filter: true,
			sort: true
		}
	}
];

export default function UserActivity() {
	return (
		<Grid container>
			<Grid item xs={12}>
				<Table model="auditlog" columns={columns} title="Users Activity" />
			</Grid>
		</Grid>
	);
}
