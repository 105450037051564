import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
	overrides: {
		MuiTableRow: {
			hover: {
				'&$root': {
					'&:hover': { backgroundColor: '#E1E1E3', cursor: 'pointer' }
				}
			}
		},
		MuiFormLabel: {
			root: {
				'&$focused': {
					color: 'rgba(0, 0, 0, 0.54)'
				}
			}
		}
	},
	props: {
		MuiTextField: {
			variant: 'outlined',
			size: 'small'
		}
	}
});

export default theme;
