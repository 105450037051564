import React from 'react';
import api from '../api';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
	IconButton,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Tooltip
} from '@material-ui/core';
import { useToasts } from 'react-toast-notifications';
import CloudDownload from '@material-ui/icons/CloudDownload';
import PrintIcon from '@material-ui/icons/Print';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { CustomDatePicker } from '.';

import { useHistory } from 'react-router-dom';

const defaultToolbarStyles = theme => ({
	iconButton: {},
	paper: {
		minWidth: '250px',
		maxWidth: '350px',
		width: '40%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: theme.spacing(3)
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
		width: '25px',
		height: '25px',
		border: `2px solid ${theme.palette.background.paper}`
	},
	form: {
		'& .MuiTextField-root': {
			marginTop: theme.spacing(2)
		},
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	button: {
		margin: theme.spacing(5, 0, 2)
	},
	largeLogo: {
		width: theme.spacing(10),
		height: theme.spacing(10)
	},
	fieldIcon: {
		marginRight: theme.spacing(1)
	}
});

function getFileName(res) {
	let filename = '';
	const disposition = res.headers['content-disposition'];
	if (disposition && disposition.indexOf('attachment') !== -1) {
		var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
		var matches = filenameRegex.exec(disposition);
		if (matches != null && matches[1]) {
			filename = matches[1].replace(/['"]/g, '');
		}
	}
	return filename;
}

async function handleExport(from) {
	const { startDate, endDate } = from;
	const url = new URL('api/export-eta-invoices', api.defaults.baseURL);
	url.searchParams.set(
		'startDate',
		moment(startDate)
			.utc()
			.toISOString()
	);
	url.searchParams.set(
		'endDate',
		moment(endDate)
			.utc()
			.toISOString()
	);
	url.searchParams.set('timeZoneOffset', moment().utcOffset());
	const res = await api.get(url);
	return saveAs(new Blob([res.data]), getFileName(res), {
		type: res.headers['content-type']
	});
}

async function handlePrint(history, addToast, handlePrintClose, from) {
	const { startDate, endDate } = from;
	api.post('/api/invoice-package', { startDate, endDate }).then(res => {
		if (res.data === 'in-progress') {
			addToast(
				'A larger number of invoices requested, you will get an email once the invoice package is ready.',
				{
					appearance: 'success',
					autoDismiss: true
				}
			);
			handlePrintClose();
		} else {
			history.push('settings/invoice-packages');
		}
	});
}

function ExportDialog({ classes, exportDialogShown, setExportDialogShown }) {
	function handleExportClose() {
		setExportDialogShown(false);
	}
	return (
		<Formik
			validationSchema={Yup.object({
				startDate: Yup.string()
					.nullable()
					.required('Required'),
				endDate: Yup.string()
					.nullable()
					.required('Required')
			})}
			initialValues={{ startDate: null, endDate: null }}
			onSubmit={handleExport}
		>
			{({ handleSubmit, isSubmitting }) => {
				return (
					<Dialog
						onClose={handleExportClose}
						open={exportDialogShown}
						fullWidth
					>
						<DialogTitle onClose={handleExportClose}>
							Export Invoices
						</DialogTitle>
						<Form className={classes.form}>
							<DialogContent>
								<Field
									name="startDate"
									label="Start Date"
									component={CustomDatePicker}
								/>
								<Field
									name="endDate"
									label="End Date"
									component={CustomDatePicker}
								/>
							</DialogContent>
							<DialogActions>
								<Button
									className={classes.button}
									variant="contained"
									color="primary"
									disabled={isSubmitting}
									onClick={handleSubmit}
								>
									Export
								</Button>
								<Button
									autoFocus
									className={classes.button}
									onClick={handleExportClose}
									variant="contained"
									color="secondary"
								>
									Cancel
								</Button>
							</DialogActions>
						</Form>
					</Dialog>
				);
			}}
		</Formik>
	);
}

function PrintDialog({ classes, printDialogShown, setPrintDialogShown }) {
	const history = useHistory();
	const { addToast } = useToasts();
	function handlePrintClose() {
		setPrintDialogShown(false);
	}
	return (
		<Formik
			validationSchema={Yup.object({
				startDate: Yup.string()
					.nullable()
					.required('Required'),
				endDate: Yup.string()
					.nullable()
					.required('Required')
			})}
			initialValues={{ startDate: null, endDate: null }}
			onSubmit={handlePrint.bind(this, history, addToast, handlePrintClose)}
		>
			{({ handleSubmit, isSubmitting }) => {
				return (
					<Dialog onClose={handlePrintClose} open={printDialogShown} fullWidth>
						<DialogTitle onClose={handlePrintClose}>
							Create Invoice Package
						</DialogTitle>
						<Form className={classes.form}>
							<DialogContent>
								<Field
									name="startDate"
									label="Start Date"
									component={CustomDatePicker}
								/>
								<Field
									name="endDate"
									label="End Date"
									component={CustomDatePicker}
								/>
							</DialogContent>
							<DialogActions>
								<Button
									className={classes.button}
									variant="contained"
									color="primary"
									disabled={isSubmitting}
									onClick={handleSubmit}
								>
									Create Package
								</Button>
								<Button
									autoFocus
									className={classes.button}
									onClick={handlePrintClose}
									variant="contained"
									color="secondary"
								>
									Cancel
								</Button>
							</DialogActions>
						</Form>
					</Dialog>
				);
			}}
		</Formik>
	);
}

class CustomToolbar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			exportDialogShown: false,
			printDialogShown: false
		};
		this.user = props.user;
	}

	render() {
		const { classes } = this.props;
		function setExportDialogShown(value) {
			this.setState({ ...this.state, exportDialogShown: value });
		}
		function setPrintDialogShown(value) {
			this.setState({ ...this.state, printDialogShown: value });
		}
		function handleExportOpen() {
			setExportDialogShown.bind(this)(true);
		}

		function handlePrintOpen() {
			setPrintDialogShown.bind(this)(true);
		}
		if (this.user.role !== 'admin') return <React.Fragment />;
		return (
			<React.Fragment>
				<Tooltip title={'Export Invoices'}>
					<IconButton
						className={classes.iconButton}
						onClick={handleExportOpen.bind(this)}
					>
						<CloudDownload className={classes.deleteIcon} />
					</IconButton>
				</Tooltip>
				<Tooltip title={'Create Invoices Package'}>
					<IconButton
						className={classes.iconButton}
						onClick={handlePrintOpen.bind(this)}
					>
						<PrintIcon className={classes.deleteIcon} />
					</IconButton>
				</Tooltip>
				<ExportDialog
					exportDialogShown={this.state.exportDialogShown}
					setExportDialogShown={setExportDialogShown.bind(this)}
					classes={classes}
				/>
				<PrintDialog
					printDialogShown={this.state.printDialogShown}
					setPrintDialogShown={setPrintDialogShown.bind(this)}
					classes={classes}
				/>
			</React.Fragment>
		);
	}
}

export default withStyles(defaultToolbarStyles, { name: 'CustomToolbar' })(
	CustomToolbar
);
