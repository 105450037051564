import React from 'react';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import image_404 from '../assets/images/404-image.jpg';

export default function PageNotFound() {
	const history = useHistory();
	return (
		<Container maxWidth="md" component="main">
			<Grid container spacing={5} alignItems="center">
				<Grid item xs />
				<Grid item xs={12} sm={10} md={8}>
					<Card elevation={0}>
						<CardContent>
							<img
								src={image_404}
								alt={'404'}
								width={'60%'}
								style={{
									display: 'block',
									marginLeft: 'auto',
									marginRight: 'auto'
								}}
							/>
							<Typography variant="h5" align="center">
								<code>Page Not Found</code>
							</Typography>
							<Typography variant="body2" align="center" color="textSecondary">
								The page you are looking for might have been removed, had its
								name changed, or is temporarily unavailable.
							</Typography>
						</CardContent>
						<CardActions>
							<Button
								fullWidth
								color="primary"
								onClick={() => history.goBack()}
								startIcon={<ArrowBackIosIcon />}
							>
								Go Back
							</Button>
						</CardActions>
					</Card>
				</Grid>
				<Grid item xs />
			</Grid>
		</Container>
	);
}
