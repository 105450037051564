import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import api from '../api';
import { Table } from '../components';
import { getRoutePrefix } from '../routes';

const columns = [
	{
		name: 'number',
		label: 'Number',
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: 'currency',
		label: 'Currency',
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: 'balance',
		label: 'Balance',
		options: {
			filter: false,
			sort: false
		}
	}
];

const CreateAccountButton = ({ parentType, record, children, history }) => {
	const routePrefix = useMemo(() => getRoutePrefix(parentType), [parentType]);

	return record && !record.id ? (
		<Button
			onClick={() => {
				api
					.post(`/api/${parentType}`, { ...record, isActive: false })
					.then(response => {
						const { data } = response;
						history.push(`${routePrefix}/${parentType}/${data.id}/account`, {
							redirectionPath: `${routePrefix}/${parentType}/${data.id}/edit`
						});
					});
			}}
		>
			{children}
		</Button>
	) : (
		<Button
			to={`${routePrefix}/${parentType}/${record.id}/account`}
			component={Link}
		>
			{children}
		</Button>
	);
};

function AccountsTable({ parentType, record }) {
	const history = useHistory();
	return (
		<Grid container>
			<Grid item xs={12}>
				<CreateAccountButton
					record={record}
					history={history}
					parentType={parentType}
				>
					Create Account
				</CreateAccountButton>
				<Table
					model="account"
					columns={columns}
					title="Accounts"
					parentId={record.id}
					parentType={parentType}
					isChild={true}
					onRowClick={accountId =>
						history.push(`${history.location.pathname}/account/${accountId}`)
					}
				/>
			</Grid>
		</Grid>
	);
}

export default AccountsTable;
