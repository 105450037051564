import React from 'react';
import { TableContainer } from '@material-ui/core';
import { FieldArray } from 'formik';
import InvoiceTable from './InvoiceTable';
import { useAppData } from '../../contexts/appContext';
import computeFunctions from '../../../shared/compute-functions';

function getFieldDefs(modelFields, computeFunctions = {}, elements = []) {
	const fieldDefs = elements.map(element => {
		const modelField = modelFields[element.field];
		const compute = computeFunctions[element.field];
		const fieldDef = { ...modelField, compute, ...element };
		return fieldDef;
	});
	return fieldDefs;
}

function Invoice({ readOnly, invoiceLineOptions }) {
	const { models } = useAppData();
	const {
		columns: invoiceLineColumns,
		modelName: invoiceLineModelName
	} = invoiceLineOptions;
	const { invoiceLinesField } = invoiceLineOptions;
	const invoiceLineModel = models[invoiceLineModelName];
	const invoiceLineComputeFunctions =
		computeFunctions[invoiceLineModelName] || {};
	const columnDefs = React.useMemo(() => {
		const { fields } = models[invoiceLineModelName];
		return getFieldDefs(
			fields,
			invoiceLineComputeFunctions,
			invoiceLineColumns
		);
	}, [invoiceLineModel, invoiceLineColumns]);
	const tableProps = { columns: columnDefs, invoiceLineOptions, readOnly };
	return (
		<TableContainer>
			<FieldArray
				name={invoiceLinesField}
				render={arrayHelpers => (
					<InvoiceTable arrayHelpers={arrayHelpers} {...tableProps} />
				)}
			/>
		</TableContainer>
	);
}
export default Invoice;
