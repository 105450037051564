import React, { useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import UserIcon from '@material-ui/icons/Person';
import PasswordIcon from '@material-ui/icons/VpnKey';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Field, Form, Formik } from 'formik';
import { Redirect, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { useFetch } from '../api';
import { TextBox } from '../components';
import { UserContext } from '../contexts/userContext';
import logo from '../assets/images/elgalla-logo-thumbnail.jpg';
import { LoadingInterceptor } from '../interceptors';

const useStyles = makeStyles(theme => ({
	paper: {
		minWidth: '250px',
		maxWidth: '350px',
		width: '40%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: theme.spacing(3)
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
		width: '25px',
		height: '25px',
		border: `2px solid ${theme.palette.background.paper}`
	},
	form: {
		'& .MuiTextField-root': {
			marginTop: theme.spacing(2)
		},
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	button: {
		margin: theme.spacing(5, 0, 2)
	},
	largeLogo: {
		width: theme.spacing(10),
		height: theme.spacing(10)
	},
	fieldIcon: {
		marginRight: theme.spacing(1)
	}
}));

export default function Login() {
	const { user, setUserState } = useContext(UserContext);
	const classes = useStyles();
	const [showPassword, setShowPassword] = React.useState(false);
	const location = useLocation();
	const { from } = location.state || { from: { pathname: '/' } };

	const [{ loading }, post] = useFetch({
		method: 'post',
		url: '/auth/login',
		manual: true
	});
	const [{ data: userData, loading: getLoading }, getUser] = useFetch({
		url: '/auth/user',
		manual: true,
		validateStatus: status => (status >= 200 && status < 300) || status === 404
	});
	React.useEffect(() => {
		setUserState(userData);
	}, [userData]);
	if (user && user !== 'Not Found') {
		return <Redirect to={from} />;
	}

	const onLogin = (values, { setSubmitting }) => {
		post({ data: values }).then(() => {
			getUser();
			setSubmitting(false);
		});
	};
	return (
		<>
			<LoadingInterceptor />
			<Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justify="center"
				style={{ minHeight: '95vh' }}
			>
				<Paper elevation={3} className={classes.paper}>
					<Badge
						overlap="circle"
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						badgeContent={
							<Avatar className={classes.avatar}>
								<LockOutlinedIcon fontSize="small" style={{ padding: '2px' }} />
							</Avatar>
						}
					>
						<Avatar
							variant="rounded"
							src={logo}
							className={classes.largeLogo}
						></Avatar>
					</Badge>
					<Typography component="h1" variant="h5">
						Sign in
					</Typography>
					<Formik
						validationSchema={Yup.object({
							username: Yup.string().required('Required'),
							password: Yup.string().required('Required')
						})}
						initialValues={{ password: '', username: '' }}
						onSubmit={onLogin}
					>
						{({ submitForm }) => (
							<Form className={classes.form}>
								<Grid container direction="column">
									<Field
										name="username"
										label="Username"
										component={TextBox}
										required={true}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<UserIcon
														color="action"
														className={classes.fieldIcon}
													/>
												</InputAdornment>
											)
										}}
									/>
									<Field
										name="password"
										label="Password"
										type={showPassword ? 'text' : 'password'}
										component={TextBox}
										required={true}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<PasswordIcon
														color="action"
														className={classes.fieldIcon}
													/>
												</InputAdornment>
											),
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={() => setShowPassword(!showPassword)}
														edge="end"
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
									<Button
										className={classes.button}
										variant="contained"
										color="primary"
										fullWidth
										onClick={submitForm}
										disabled={loading || getLoading}
										type="submit"
									>
										Login
									</Button>
								</Grid>
							</Form>
						)}
					</Formik>
				</Paper>
			</Grid>
		</>
	);
}
