import _ from 'lodash';
import React, { useEffect, useCallback } from 'react';
import { Field, withFormik, yupToFormErrors } from 'formik';
import { Chip, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ActionButtons, AlertDialog, FormField } from '.';
import api from '../api';
import fieldTypes from '../field-types';
import { useAppData } from '../contexts/appContext';
import { useUserContext } from '../contexts/userContext';

const useStyles = makeStyles(theme => ({
	form: {
		'& .MuiTextField-root': {
			margin: theme.spacing(1)
		},
		'& .MuiButton-root': {
			margin: theme.spacing(1)
		}
	}
}));

const updateURL = url => {
	window.history.replaceState(null, null, url);
};

function convertToDbValues(values, fields, models) {
	let dbValues = {};
	Object.keys(values).forEach(key => {
		const fieldDef = fields[key];
		let fieldType = fieldTypes[fieldDef?.type] || fieldTypes['textbox'];
		if (fieldType.convertToDb) {
			dbValues[key] = fieldType.convertToDb(values[key], {
				fieldDef,
				fieldTypes,
				models,
				convertToDbValues
			});
		} else {
			if (values[key] === '') dbValues[key] = null;
			else dbValues[key] = values[key];
		}
	});
	return dbValues;
}

function convertToFormValues(values, formFields, fields, models) {
	let formValues = {};
	const fieldNames = _.union(_.keys(values), _.keys(fields));
	fieldNames.forEach(fieldName => {
		if (!formFields.some(formField => formField.name === fieldName)) {
			if (!_.isUndefined(values[fieldName]))
				formValues[fieldName] = values[fieldName];
		} else {
			const fieldDef = fields[fieldName];
			let fieldType = fieldTypes[fieldDef.type] || fieldTypes['textbox'];
			if (fieldType.convertToForm) {
				formValues[fieldName] = fieldType.convertToForm(values[fieldName], {
					fieldDef,
					models,
					convertToFormValues
				});
			} else {
				if (values[fieldName] == null) formValues[fieldName] = '';
				else formValues[fieldName] = values[fieldName];
			}
		}
	});

	return formValues;
}

function processRules({ fields, values, rulesService, models }) {
	const convertedValues = convertToDbValues(values, fields, models);
	return rulesService.processRules(convertedValues);
}

function Form({
	recordId = null,
	parentId = null,
	parentType = null,
	hasActions = true,
	redirectionPathMap,
	rulesService,
	modelName,
	routePrefix,
	pluralModelName,
	formFields,
	formFlags,
	models,
	addToast,
	setMode,
	mode,
	history,
	fields,
	resetForm,
	isSubmitting,
	setSubmitting,
	values,
	handleSubmit,
	setStatus,
	setFieldValue,
	setValues,
	status,
	isDraftable,
	customActionButtons,
	aclCheck
}) {
	const classes = useStyles();
	const { user } = useUserContext();
	const { config } = useAppData();
	const {
		canCreate = true,
		canDelete = true,
		canUpdate = true
	} = useCallback(aclCheck(values), [JSON.stringify(values)]);
	useEffect(() => {
		if (rulesService.rules) {
			const processedRules = processRules({
				fields,
				rulesService,
				values,
				models,
				config
			});
			setStatus({ ...status, rules: processedRules });
		}
	}, [JSON.stringify(values)]);

	const customButtons = React.useMemo(() => {
		if (customActionButtons?.length) {
			return customActionButtons.map(buttonProps => ({
				...buttonProps,
				onClick: () =>
					buttonProps.onClick({
						values,
						setValues,
						setFieldValue,
						addToast,
						setSubmitting
					})
			}));
		}
		return [];
	}, [customActionButtons, JSON.stringify(values)]);

	const handleDelete = setSubmitting => {
		setSubmitting(true);
		api
			.delete(`/api/${modelName}/${recordId}`)
			.then(() => {
				setSubmitting(false);
				addToast(`${_.capitalize(modelName)} Deleted`, {
					appearance: 'success',
					autoDismiss: true
				});
				if (redirectionPathMap) {
					history.push(redirectionPathMap.list);
				} else {
					history.push(
						parentType && parentId
							? `${routePrefix}/${parentType}/${parentId}`
							: `${routePrefix}/${pluralModelName}`
					);
				}
			})
			.catch(() => {
				setSubmitting(false);
			});
	};

	const onCancel = resetForm => {
		resetForm();
		setMode('view');
		if (redirectionPathMap) {
			updateURL(redirectionPathMap.view);
		} else {
			updateURL(
				parentId && parentType
					? `/${parentType}/${parentId}/${modelName}/${recordId}`
					: `${routePrefix}/${modelName}/${recordId}`
			);
		}
	};

	const isViewMode = () => {
		return recordId && mode === 'view';
	};
	const isEditable = editableFn => {
		// if editableFn not exists, then returns true if form is editable
		if (!editableFn) return !isViewMode();
		if (editableFn) {
			return !isViewMode() && editableFn(values, config);
		}
	};
	return (
		<Grid container className={classes.root}>
			<Grid item xs={12}>
				{formFlags ? (
					formFlags.map((flag, index) => {
						const { display, color, text } = flag;
						return display && display({ values, mode, user }) ? (
							<Chip
								key={`form-flag-${index}`}
								color={color}
								label={text}
							></Chip>
						) : null;
					})
				) : (
					<></>
				)}
			</Grid>
			<Grid item xs={10}>
				<form className={classes.form}>
					{formFields ? (
						formFields.map((field, index) => {
							const {
								display,
								editable,
								component: Component,
								...otherFields
							} = field;
							return !display || display({ values, mode, user, config }) ? (
								<Grid
									key={`${modelName}.${index}`}
									item
									xs={field.width || 8}
									className={!field.component ? classes.field : ''}
								>
									{Component ? (
										<Component
											readOnly={!isEditable(editable)}
											record={values}
											mode={mode}
											model={modelName}
											{...otherFields}
										/>
									) : (
										<Field
											readOnly={!isEditable(editable)}
											record={values}
											{...otherFields}
											mode={mode}
											config={config}
											component={FormField}
										/>
									)}
								</Grid>
							) : null;
						})
					) : (
						<CircularProgress />
					)}
				</form>
			</Grid>
			{hasActions && (
				<Grid item xs={2}>
					<Grid container justify="flex-end">
						{isViewMode() ? (
							<ActionButtons
								disabled={isSubmitting}
								onEdit={
									canUpdate !== false
										? () => {
												setMode('edit');
												if (redirectionPathMap) {
													updateURL(redirectionPathMap.edit);
												} else {
													updateURL(
														parentId && parentType
															? `/${parentType}/${parentId}/${modelName}/${recordId}/edit`
															: `${routePrefix}/${modelName}/${recordId}/edit`
													);
												}
										  }
										: null
								}
								onDelete={
									canDelete !== false
										? () => setStatus({ ...status, confirmDialogOpen: true })
										: null
								}
								customButtons={customButtons}
								form={{ setFieldValue, setValues, values, mode }}
							/>
						) : !isDraftable || values.status === 'active' ? (
							<ActionButtons
								disabled={isSubmitting}
								onSave={canCreate !== false ? handleSubmit : null}
								onCancel={recordId ? () => onCancel(resetForm) : null}
								customButtons={customButtons}
								form={{ setFieldValue, values, mode }}
							/>
						) : (
							<ActionButtons
								disabled={isSubmitting}
								onSave={
									canCreate !== false
										? () => {
												values.status = 'active';
												handleSubmit();
										  }
										: null
								}
								onSaveDraft={
									canCreate !== false
										? () => {
												values.status = 'draft';
												handleSubmit();
										  }
										: null
								}
								onCancel={recordId ? () => onCancel(resetForm) : null}
								customButtons={customButtons}
								form={{ setFieldValue, values, mode }}
							/>
						)}
					</Grid>
				</Grid>
			)}
			<AlertDialog
				title={'Please Confirm'}
				open={status.confirmDialogOpen}
				setOpen={value => setStatus({ ...status, confirmDialogOpen: value })}
				confirmationText="delete"
				cancellationText="cancel"
				onConfirm={() => handleDelete(setSubmitting)}
			>
				Are you sure you want to delete this {modelName}?
			</AlertDialog>
		</Grid>
	);
}

const EnhancedForm = withFormik({
	mapPropsToValues: ({ fields, formFields, initialValues, models }) =>
		convertToFormValues(initialValues, formFields, fields, models),
	mapPropsToStatus: ({ fields, initialValues, rulesService, models }) => {
		const rules = processRules({
			fields,
			values: initialValues,
			rulesService,
			models
		});
		return { confirmDialogOpen: false, rules };
	},
	enableReinitialize: true,

	validate: async (values, props) => {
		const { validationSchema, rulesService } = props;
		const convertedValues = convertToDbValues(
			values,
			props.fields,
			props.models
		);
		const rules = rulesService.processRules(convertedValues);
		return validationSchema
			.validate(convertedValues, { context: rules, abortEarly: false })
			.then(() => {})
			.catch(err => {
				if (err.name === 'ValidationError') {
					return yupToFormErrors(err);
				} else throw err;
			});
	},

	handleSubmit: (values, { setValues, props }) => {
		const {
			parentType,
			parentId,
			fields,
			formFields,
			recordId,
			modelName,
			models,
			redirectionPathMap,
			submitParentType = true,
			parentIdName = 'parentId',
			history,
			historyState,
			routePrefix,
			pluralModelName,
			setMode,
			addToast,
			customSaveFunction
		} = props;

		if (parentType && submitParentType) values.parentType = parentType;
		if (parentId) values[parentIdName] = parentId;
		const dbValues = convertToDbValues(values, fields, models);

		if (customSaveFunction) {
			customSaveFunction(values, dbValues, props);
			return;
		}
		return api
			.post(`/api/${modelName}${`${recordId ? `/${recordId}` : ''}`}`, dbValues)
			.then(response => {
				if (!response) return;
				addToast(
					`${_.capitalize(modelName)} ${recordId ? 'Updated' : 'Created'}`,
					{
						appearance: 'success',
						autoDismiss: true
					}
				);

				if (historyState?.redirectionPath) {
					history.push(historyState.redirectionPath);
				} else if (redirectionPathMap) {
					history.push(redirectionPathMap[recordId ? 'view' : 'list']);
				} else if (!recordId) {
					history.push(
						parentType && parentId
							? `${routePrefix}/${parentType}/${parentId}`
							: `${routePrefix}/${pluralModelName}`
					);
				} else {
					setMode('view');
					// update the form fields with the returned values
					props.resetForm({
						values: convertToFormValues(
							response.data,
							formFields,
							fields,
							models
						)
					});
					setValues(
						convertToFormValues(response.data, formFields, fields, models)
					);

					updateURL(
						parentId && parentType
							? `${routePrefix}/${parentType}/${parentId}/${modelName}/${recordId}`
							: `${routePrefix}/${modelName}/${recordId}`
					);
				}
			});
	},

	displayName: 'Form'
})(Form);

export default EnhancedForm;
