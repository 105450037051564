import React from 'react';
import { Route } from 'react-router-dom';
import { UserContext } from '../contexts/userContext';
import Breadcrumbs from '../components/Breadcrumbs';
import { useAppData } from '../contexts/appContext';

const RouteWithSubRoutes = ({ routes, ...route }) => {
	const { user } = React.useContext(UserContext);
	const appData = useAppData();
	return (
		<Route
			path={route.path}
			render={props => {
				return (
					<>
						{user &&
							!(['/', '*'].indexOf(route.path) > -1) &&
							typeof routes == 'function' && (
								<Breadcrumbs {...route} routes={routes(appData)} />
							)}
						{/* pass the sub-routes down to keep nesting */}
						<route.component {...props} routes={routes} />
					</>
				);
			}}
		/>
	);
};

export default RouteWithSubRoutes;
