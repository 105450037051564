import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Table } from '../components';
import { useAppData } from '../contexts/appContext';

const columns = [
	{
		name: 'number',
		label: 'Number',
		options: { filter: false, sort: false }
	},
	{
		name: 'amount',
		label: 'Amount',
		options: { filter: false, sort: false }
	},
	{
		name: 'date',
		label: 'Date',
		options: { filter: false, sort: false }
	},
	{
		name: 'type',
		label: 'Type',
		options: { filter: false, sort: false }
	},
	{
		name: 'expenseType',
		label: 'Expense Type',
		options: { filter: false, sort: false }
	}
];

function TransactionsTable({ parentType, parentId, parentMode }) {
	const history = useHistory();
	const appData = useAppData();
	const relationMappings = appData
		? appData.models['transaction'].relationMappings
		: undefined;
	const parentIdName = relationMappings
		? relationMappings[`parentRelation_${parentType}`].join.from.split('.')[1]
		: '';
	return (parentMode === 'view' && !parentId) ||
		parentMode !== 'view' ? null : (
		<Grid container>
			<Grid item xs={12}>
				<Table
					model="transaction"
					columns={columns}
					title="Transactions"
					parentId={parentId}
					parentIdName={parentIdName}
					isChild={true}
					onRowClick={transactionId =>
						history.push(
							`${history.location.pathname}/transaction/${transactionId}`
						)
					}
				/>
			</Grid>
		</Grid>
	);
}

export default TransactionsTable;
