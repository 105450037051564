import React from 'react';
import { GenericModelTable } from '../components';

const columns = [
	{
		name: 'name',
		label: 'Name',
		options: {
			filter: true,
			sort: true
		}
	}
];
const modelName = 'dropdown';

function Dropdowns() {
	const tableOptions = {
		modelName,
		modelNamePlural: 'Dropdowns',
		columns
	};
	return <GenericModelTable {...tableOptions} />;
}

export default Dropdowns;
