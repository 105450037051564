import React from 'react';
import { TableContainer } from '@material-ui/core';
import { FieldArray } from 'formik';
import BillTable from './BillTable';
import { useAppData } from '../../contexts/appContext';
import computeFunctions from '../../../shared/compute-functions';

function getFieldDefs(modelFields, computeFunctions, elements = []) {
	const fieldDefs = elements.map(element => {
		const modelField = modelFields[element.field];
		const compute = computeFunctions[element.field];
		const fieldDef = { ...modelField, compute, ...element };
		return fieldDef;
	});
	return fieldDefs;
}

function Bill({ readOnly, billLineOptions }) {
	const { models } = useAppData();
	const {
		columns: billLineColumns,
		modelName: billLineModelName
	} = billLineOptions;
	const { billLinesField } = billLineOptions;
	const billLineModel = models[billLineModelName];
	const billLineComputeFunctions = computeFunctions[billLineModelName] || {};
	const columnDefs = React.useMemo(() => {
		const { fields } = models[billLineModelName];
		return getFieldDefs(fields, billLineComputeFunctions, billLineColumns);
	}, [billLineModel, billLineColumns]);
	const tableProps = { columns: columnDefs, billLineOptions, readOnly };
	return (
		<TableContainer>
			<FieldArray
				name={billLinesField}
				render={arrayHelpers => (
					<BillTable arrayHelpers={arrayHelpers} {...tableProps} />
				)}
			/>
		</TableContainer>
	);
}
export default Bill;
