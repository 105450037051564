import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { ErrorMessage, Field, FieldArray, useFormikContext } from 'formik';

import fieldTypes from '../field-types';

const useStyles = makeStyles(() => ({
	elementRow: {
		minWidth: '350px'
	}
}));

function MultiInput({ arrayName, initialLength, ...field }) {
	const classes = useStyles();
	const { values, setFieldValue } = useFormikContext();

	const initialRowElement = React.useMemo(() => {
		const fieldsValue = {};
		for (const property of field.properties) {
			fieldsValue[property.name] = '';
		}
		return fieldsValue;
	}, [JSON.stringify(field.properties)]);

	React.useEffect(() => {
		if (initialLength > 0 && !values?.id) {
			const initialArrayValue = [];
			for (let i = 0; i < initialLength; i++) {
				initialArrayValue.push(initialRowElement);
			}
			setFieldValue(arrayName, [initialRowElement]);
		}
	}, [arrayName, JSON.stringify(initialRowElement)]);

	return (
		<>
			<FieldArray
				name={arrayName}
				render={arrayHelpers => {
					const fieldValues = values[arrayName];
					return (
						<>
							{fieldValues && fieldValues.length > 0
								? fieldValues.map((_, rowIndex) => (
										<Grid
											container
											className={classes.elementRow}
											direction="row"
											alignItems="center"
											justify="center"
											key={rowIndex}
										>
											<Grid item xs>
												<Grid
													container
													direction="row"
													alignItems="baseline"
													spacing={1}
													key={rowIndex}
												>
													{field.properties.map((item, colIndex) => {
														const elementName = `${arrayName}.${rowIndex}.${item.name}`;
														return (
															<Grid item xs key={`${elementName}.${colIndex}`}>
																<Field
																	{...item}
																	record={field.record}
																	name={elementName}
																	component={fieldTypes[item.type].component}
																	size="small"
																/>
															</Grid>
														);
													})}
												</Grid>
											</Grid>
											<Grid item xs="auto">
												<IconButton
													color="secondary"
													aria-label="remove"
													onClick={() => arrayHelpers.remove(rowIndex)}
												>
													<RemoveCircleIcon />
												</IconButton>
											</Grid>
										</Grid>
								  ))
								: null}
							<Button
								variant="outlined"
								color="primary"
								size="small"
								startIcon={<AddCircleIcon />}
								style={{ textTransform: 'capitalize', borderRadius: '50px' }}
								onClick={() => arrayHelpers.push(initialRowElement)}
							>
								Add Element
							</Button>
						</>
					);
				}}
			/>
			<ErrorMessage
				name={arrayName}
				render={msg =>
					typeof msg === 'string' && (
						<FormHelperText id={arrayName}>{msg}</FormHelperText>
					)
				}
			/>
		</>
	);
}
export default MultiInput;
