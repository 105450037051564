import React from 'react';
import { GenericModelTable } from '../components';

const columns = [
	{
		name: 'storeId',
		label: 'Store',
		options: { filter: true, sort: true, searchable: false }
	},
	{
		name: 'billNumber',
		label: 'Bill Number',
		options: { filter: true, sort: true, searchable: true }
	},
	{
		name: 'partyType',
		label: 'Party Type',
		options: { sort: true, filter: true, searchable: false }
	},
	{
		name: 'partyId',
		label: 'Party',
		options: { sort: true, filter: true, searchable: false }
	},
	{
		name: 'partyAccountId',
		label: 'Party Account',
		options: { sort: true, filter: true, searchable: false }
	},
	{
		name: 'amount',
		label: 'Amount',
		options: { sort: true, filter: true, searchable: false }
	}
];
const modelName = 'bill';

function Bills() {
	const tableOptions = {
		modelName,
		modelNamePlural: 'Bills',
		columns
	};
	return <GenericModelTable {...tableOptions} />;
}

export default Bills;
