import React from 'react';
import { defineAbility, subject } from '@casl/ability';
import { capitalize } from 'lodash';
import { Table, ModelForm } from '.';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Grid
} from '@material-ui/core';
import { useUserContext } from '../contexts/userContext';
import { useAppData } from '../contexts/appContext';
import api from '../api';

const columns = [
	{
		name: 'number',
		label: 'Number',
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: 'amount',
		label: 'Amount',
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: 'date',
		label: 'Date',
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: 'type',
		label: 'Type',
		options: {
			filter: false,
			sort: false
		}
	}
];

function canCreate(model, user) {
	const { ability } = model.acl ? model.acl(user, defineAbility) : {};
	if (!ability) return true;
	return ability.can(
		'create',
		subject(capitalize(model.name), {
			expense: false,
			parentType: 'invoice',
			invoice_storeId: user.storeIds[0]
		})
	);
}

function InvoicePayment(props) {
	const { record, model } = props;
	const [state, setState] = React.useState({
		dialogShow: false,
		reloadTable: false
	});
	const { user } = useUserContext();
	const { models } = useAppData();

	const handleClose = reloadTable => {
		setState({ dialogShow: false, reloadTable });
	};
	const handleSaveTransaction = (values, dbValues, props) => {
		api.post('/api/transaction', dbValues).then(response => {
			if (!response) return;
			props.addToast('Transaction Created', {
				appearance: 'success',
				autoDismiss: true
			});
			handleClose(!state.reloadTable);
		});
	};
	const canCreatePayment = canCreate(models['transaction'], user);
	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					{canCreatePayment ? (
						<Button
							onClick={() => {
								setState({ ...state, dialogShow: true });
							}}
						>
							Create
						</Button>
					) : null}
					<Table
						model="transaction"
						columns={columns}
						title="Payments"
						reloadProp={state.reloadTable}
						parentId={record.id}
						isChild={true}
						customFilters={{
							type: 'debit'
						}}
						onRowClick={transactionId => {
							setState({ ...state, dialogShow: true, transactionId });
						}}
					/>
				</Grid>
			</Grid>
			<Dialog onClose={handleClose} open={state.dialogShow} fullWidth>
				<DialogTitle onClose={handleClose}>Add Payment Transaction</DialogTitle>
				<DialogContent>
					<ModelForm
						recordId={state.transactionId}
						parentId={record.id}
						parentType={model}
						formName={'transactionForm'}
						defaultValues={{
							storeId: record.storeId,
							accountId: record.partyAccountId,
							expense: false,
							date: new Date().toISOString(),
							parentId: record.id,
							parentType: model,
							type: 'debit'
						}}
						initialMode="edit"
						customSaveFunction={handleSaveTransaction}
					/>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleClose} color="secondary">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default InvoicePayment;
