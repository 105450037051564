import React from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import { useHistory, useParams } from 'react-router-dom';
import { ModelForm } from '../components';
import { UserContext } from '../contexts/userContext';
export default function Expense({ formMode }) {
	const { id } = useParams();
	const { user } = React.useContext(UserContext);
	const history = useHistory();

	let defaultValues = {};
	if (!id) {
		defaultValues = {
			accountId: history.location.state?.accountId,
			expense: true,
			type: 'credit',
			storeId: user.selectedStore,
			date: moment().toISOString()
		};
	}

	return (
		<Grid container>
			<ModelForm
				recordId={id}
				formName={'transactionForm'}
				initialMode={formMode}
				defaultValues={defaultValues}
				redirectionPathMap={{
					list: '/expenses',
					edit: `/expense/${id}/edit`,
					view: `/expense/${id}`
				}}
			/>
		</Grid>
	);
}
