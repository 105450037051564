import React from 'react';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import TableCell from '@material-ui/core/TableCell';
import { Field } from 'formik';
import { FormField } from '../';

const useStyles = makeStyles(() => ({
	field: {
		maxWidth: '200px',
		minWidth: '180px'
	}
}));

function BilTableCell({ field, lineIndex, billLineOptions, ...props }) {
	const { record } = props;
	const classes = useStyles();
	const { billLinesField } = billLineOptions;
	const name = `${billLinesField}.${lineIndex}.${field}`;
	const value = get(record, name);
	if (props.compute) {
		let [...dependentFields] = props.compute.dependentFields;
		dependentFields = dependentFields.map(
			field => `${billLinesField}.${lineIndex}.${field}`
		);
		props.compute = {
			...props.compute,
			dependentFields
		};
	}
	return (
		<TableCell>
			<Field
				classes={classes}
				name={`${billLinesField}.${lineIndex}.${field}`}
				hideLabel={true}
				value={value}
				{...props}
				record={get(record, `${billLinesField}.${lineIndex}`)}
				fieldWidth={12}
				component={FormField}
			/>
		</TableCell>
	);
}
export default BilTableCell;
