const _ = require('lodash');

const bill = require('./bill');
const client = require('./client');
const dropdownitem = require('./dropdownitem');
const invoice = require('./invoice');
const invoiceLine = require('./invoice-line');
const item = require('./item');
const parcel = require('./parcel');
const transaction = require('./transaction');

const computeFields = ({ ent, model, config }) => {
	const compute = require(`./${model}`);
	if (!compute) return ent;
	const keys = _.union(Object.keys(ent), Object.keys(compute));
	keys.forEach(key => {
		if (compute[key]) {
			let someDepFieldsMissing = false;
			let allDepFieldsMissing = true;
			compute[key].dependentFields.forEach(depField => {
				if (!ent[depField]) someDepFieldsMissing = true;
				else allDepFieldsMissing = false;
			});

			// if All dependent fields are missing, then return undefined as no change should be made
			// if only some are missing, return null as now the previous calculated value is false OR the calculation is not possible
			if (someDepFieldsMissing) {
				ent[key] = allDepFieldsMissing ? undefined : null;
			} else ent[key] = compute[key].formulate(ent, config);
		}
	});
	return ent;
};

module.exports = {
	computeFields,
	bill,
	client,
	dropdownitem,
	invoice,
	invoiceLine,
	item,
	parcel,
	transaction
};
