import React from 'react';
import FormTable from './FormTable';

function DropdownItemsTable({ model, parent }) {
	return (
		<FormTable
			type="dropdownitem"
			title="Dropdown Items"
			parent={parent}
			model={model}
			parentIdName={'dropdownId'}
			submitParentType={false}
		/>
	);
}
export default DropdownItemsTable;
