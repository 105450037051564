const modelName = 'transaction';

export default {
	model: modelName,
	elements: [
		{
			field: 'accountId',
			display: () => false
		},
		{
			field: 'number',
			display: ({ values }) => {
				//After creation
				return Boolean(values.id);
			},
			editable: () => false
		},
		{
			field: 'amount'
		},
		{
			field: 'date'
		},
		{
			field: 'type',
			display: () => false
		},
		{
			field: 'parentId',
			display: () => false
		},
		{
			field: 'expense',
			display: () => false
		},
		{
			field: 'expenseType',
			display: ({ values }) => {
				return values.expense;
			}
		}
	]
};
