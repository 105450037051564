import React from 'react';
import { capitalize } from 'lodash';
import { defineAbility, subject } from '@casl/ability';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import { Table } from '../components';
import { useUserContext } from '../contexts/userContext';
import { useAppData } from '../contexts/appContext';
import { useFetch } from '../api';

const columns = [
	{
		name: 'number',
		label: 'Number',
		options: { filter: false, sort: false }
	},
	{
		name: 'account_number',
		label: 'Account',
		options: { filter: false, sort: false }
	},
	{
		name: 'amount',
		label: 'Amount',
		options: { filter: false, sort: false }
	},
	{
		name: 'date',
		label: 'Date',
		options: { filter: false, sort: true }
	},
	{
		name: 'type',
		label: 'Type',
		options: { filter: false, sort: false }
	},
	{
		name: 'expenseType',
		label: 'Expense Type',
		options: { filter: false, sort: false }
	}
];

function canCreate(model, user) {
	const { ability } = model.acl ? model.acl(user, defineAbility) : {};
	if (!ability) return true;
	return ability.can(
		'create',
		subject(capitalize(model.name), { expense: true })
	);
}
const modelName = 'transaction';

function Expenses() {
	const modelRoute = '/expense';
	const history = useHistory();
	const { user } = useUserContext();
	const { models } = useAppData();
	const [{ data }] = useFetch({
		url: `/api/account?parentId:eq=${user.selectedStore}`
	});

	const accountId = React.useMemo(() => {
		return data?.[0].id;
	}, [data]);

	const goTo = path => {
		history.push(path, { accountId });
	};
	const canUserCreate = canCreate(models[modelName], user);
	return (
		<Grid container>
			<Grid item xs={12}>
				{canUserCreate ? (
					<Button to={modelRoute} onClick={() => goTo(`${modelRoute}`)}>
						Create
					</Button>
				) : null}
				{accountId && (
					<Table
						model={modelName}
						columns={columns}
						title="Expenses"
						customFilters={{
							expense: true,
							accountId
						}}
						onRowClick={expenseId => goTo(`${modelRoute}/${expenseId}`)}
					/>
				)}
			</Grid>
		</Grid>
	);
}

export default Expenses;
