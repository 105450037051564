import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { fieldToDateTimePicker } from 'formik-material-ui-pickers';
import {
	KeyboardDateTimePicker,
	MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles(() => ({
	dateField: {
		'& .MuiOutlinedInput-adornedEnd': {
			paddingRight: '0px'
		},
		'& .MuiInputAdornment-positionEnd': {
			marginLeft: '0px'
		}
	}
}));

function CustomDateTimePicker(props) {
	const classes = useStyles();
	const { form, field, label } = props;
	const dateTimeProps = fieldToDateTimePicker({ form, field, label });
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardDateTimePicker
				className={classes.dateField}
				{...dateTimeProps}
				fullWidth
				variant="inline"
				format="dd-MM-yyyy hh:mm aa"
				value={field.value}
			/>
		</MuiPickersUtilsProvider>
	);
}

export default CustomDateTimePicker;
