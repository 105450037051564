import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
	breadcrumbs: {
		paddingBottom: theme.spacing(3)
	}
}));

const LinkRouter = props => <Link {...props} component={RouterLink} />;

const BreadcrumbsItem = ({ children, isLast, to }) => {
	return isLast ? (
		<Typography color="textPrimary">{children}</Typography>
	) : (
		<LinkRouter color="inherit" to={to || '/'}>
			{children}
		</LinkRouter>
	);
};

export default function RouterBreadcrumbs({
	location,
	path,
	routes,
	...props
}) {
	const classes = useStyles();
	const pathnames = location.pathname.split('/').filter(v => v);
	const paths = path.split('/').filter(v => v);

	const swapWithParams = (path, params) => {
		if (!Object.keys(params).length) return path;
		return Object.keys(params).reduce((path, param) => {
			return path.replace(`:${param}`, params[param]);
		}, path);
	};

	return (
		<Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
			<BreadcrumbsItem pathnames={pathnames}>
				{_.find(routes, ['path', '/']).breadcrumb.name}
			</BreadcrumbsItem>
			{pathnames.map((value, index) => {
				const last = index === pathnames.length - 1;
				const route = _.find(routes, [
					'path',
					`/${paths.slice(0, index + 1).join('/')}`
				]);
				if (!(route && route.breadcrumb)) return;

				const { pathRef, name } = route.breadcrumb;
				const to =
					pathRef && pathRef.includes(':')
						? swapWithParams(pathRef, props.computedMatch.params)
						: pathRef;
				return (
					(last || to) && (
						<BreadcrumbsItem key={`${to}${index}`} to={to} isLast={last}>
							{name ? name : value}
						</BreadcrumbsItem>
					)
				);
			})}
		</Breadcrumbs>
	);
}
