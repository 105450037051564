import React from 'react';
import { defineAbility } from '@casl/ability';
import { Grid } from '@material-ui/core';
import { Table } from '../components';
import { getRoutePrefix } from '../routes';
import { useHistory } from 'react-router-dom';
import { useUserContext } from '../contexts/userContext';
import { useAppData } from '../contexts/appContext';

const invoiceColumns = [
	{
		name: 'storeId',
		label: 'Store'
	},
	{
		name: 'invoiceNumber',
		label: 'Invoice Number'
	},
	{
		name: 'totalPrice',
		label: 'Amount'
	},
	{
		name: 'invoiceDate',
		label: 'Invoice Date'
	},
	{
		name: 'createdDate',
		label: 'Created Date'
	}
];

const billColumns = [
	{
		name: 'storeId',
		label: 'Store'
	},
	{
		name: 'partyType',
		label: 'Party Type'
	},
	{
		name: 'partyId',
		label: 'Party'
	},
	{
		name: 'partyAccountId',
		label: 'Party Account'
	},
	{
		name: 'amount',
		label: 'Amount'
	},
	{
		name: 'createdDate',
		label: 'Created Date'
	}
];

function Dashboard() {
	const history = useHistory();
	const { user } = useUserContext();
	const { models } = useAppData();
	const billModel = models.bill;
	const invoiceModel = models.invoice;
	const invoiceModelName = invoiceModel.name;
	const invoiceModelRoute = `${getRoutePrefix(
		invoiceModelName
	)}/${invoiceModelName}`;
	const billModelName = billModel.name;
	const billModelRoute = `${getRoutePrefix(billModelName)}/${billModelName}`;
	const tableOptions = {
		filter: false,
		pagination: false,
		search: false,
		sort: false,
		sortOrder: {
			name: 'createdDate',
			direction: 'desc'
		},
		rowsPerPage: 5,
		viewColumns: false
	};
	const { ability: invoiceAbility } = invoiceModel.acl
		? invoiceModel.acl(user, defineAbility)
		: {};
	const { ability: billAbility } = billModel.acl
		? billModel.acl(user, defineAbility)
		: {};
	const canReadInvoices = invoiceAbility.can('read', 'Invoice');
	const canReadBills = billAbility.can('read', 'Bill');
	return (
		<Grid container spacing={2}>
			{canReadInvoices ? (
				<Grid item xs={12}>
					<Table
						model={'invoice'}
						title={'Recent Invoices'}
						columns={invoiceColumns}
						tableOptions={tableOptions}
						onRowClick={invoiceId =>
							history.push(`${invoiceModelRoute}/${invoiceId}`)
						}
					/>
				</Grid>
			) : null}
			{canReadBills ? (
				<Grid item xs={12}>
					<Table
						model={'bill'}
						title={'Recent Bills'}
						columns={billColumns}
						tableOptions={tableOptions}
						onRowClick={billId => history.push(`${billModelRoute}/${billId}`)}
					/>
				</Grid>
			) : null}
		</Grid>
	);
}

export default Dashboard;
