import React from 'react';
import { capitalize } from 'lodash';
import { defineAbility } from '@casl/ability';
import { Link, useHistory } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import { Table } from '../components';
import { getRoutePrefix } from '../routes';
import { useUserContext } from '../contexts/userContext';
import { useAppData } from '../contexts/appContext';

function canCreate(model, user) {
	const { ability } = model.acl ? model.acl(user, defineAbility) : {};
	if (!ability) return true;
	return ability.can('create', capitalize(model.name));
}

function GenericModelTable(props) {
	const {
		modelName,
		modelNamePlural,
		columns,
		tableOptions,
		onRowClick
	} = props;
	const modelRoute = `${getRoutePrefix(modelName)}/${modelName}`;
	const history = useHistory();
	const { user } = useUserContext();
	const { models } = useAppData();
	const model = models[modelName];
	const userCanCreate = canCreate(model, user);
	return (
		<Grid container>
			<Grid item xs={12}>
				{userCanCreate ? (
					<Button to={modelRoute} component={Link}>
						Create
					</Button>
				) : null}
				<Table
					onRowClick={
						onRowClick
							? onRowClick
							: itemId => history.push(`${modelRoute}/${itemId}`)
					}
					model={modelName}
					title={modelNamePlural}
					columns={columns}
					tableOptions={tableOptions}
				/>
			</Grid>
		</Grid>
	);
}

export default GenericModelTable;
