import React, { useState, useEffect } from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { UserContext } from './contexts/userContext';
import { useFetch } from './api';
import { routes, RouteWithSubRoutes } from './routes';
import { ThemeProvider } from '@material-ui/core/styles';
import themes from './themes';
import { ToastProvider } from 'react-toast-notifications';
import { ErrorInterceptor } from './interceptors';
import '@peculiar/fortify-webcomponents/dist/peculiar/peculiar.css';

function App() {
	const [initialLoad, setInitialLoad] = useState(true);
	const [userState, setUserState] = useState(null);
	let [{ error, data }] = useFetch({
		url: 'auth/user'
	});
	useEffect(() => {
		setUserState(data);
		if (data || error) setInitialLoad(false);
	}, [data, error]);
	if (initialLoad) return <CircularProgress />;
	return (
		<ThemeProvider theme={themes}>
			<ToastProvider placement="bottom-right">
				<title> El Galla | Invoicing</title>
				<ErrorInterceptor />
				<UserContext.Provider value={{ user: userState, setUserState }}>
					<Router>
						<Switch>
							{routes(userState).map((route, i) => {
								return <RouteWithSubRoutes key={i} {...route} />;
							})}
						</Switch>
					</Router>
				</UserContext.Provider>
			</ToastProvider>
		</ThemeProvider>
	);
}

export default App;
