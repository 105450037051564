import { useLayoutEffect, useRef } from 'react';
import { useAppData } from '../contexts/appContext';
import api from '../api';

function CSRFInerceptor() {
	const csrfInterceptor = useRef(null);
	const appData = useAppData();

	const addCsrfInterceptor = () => {
		const interceptor = api.interceptors.request.use(request => {
			const csrfHeaderName = request.xsrfHeaderName;
			request.headers[csrfHeaderName] = appData.csrfToken;
			return request;
		});
		csrfInterceptor.current = { interceptor };
	};

	const removeCsrfInterceptor = () => {
		if (csrfInterceptor.current) {
			api.interceptors.request.eject(csrfInterceptor.current.interceptor);
			csrfInterceptor.current = null;
		}
	};

	useLayoutEffect(() => {
		addCsrfInterceptor();
		return () => {
			removeCsrfInterceptor();
		};
		// appData doesn't change if it ever refreshes, this use effect needs to be rethought
	}, [appData]);

	return null;
}

export default CSRFInerceptor;
