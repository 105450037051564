import { Typography } from '@material-ui/core';
import React from 'react';
import { TextBox } from '../components';
import textAreaFieldType from '../../shared/field-types/textArea';

export default {
	...textAreaFieldType,
	component: function textarea(props) {
		return <TextBox {...props} multiline rows={5} />;
	},
	format: ({ field, record }) => {
		return record[field];
	},
	queryParameter: ({ name }) => name,
	readOnlyComponent: function textarea(props) {
		return (
			<Typography style={{ wordWrap: 'break-word' }}>
				{props.field.value}
			</Typography>
		);
	}
};
