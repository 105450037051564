import React from 'react';
import { CustomDatePicker } from '../components';
import moment from 'moment';
import sharedDateFieldType from '../../shared/field-types/date';

export default {
	...sharedDateFieldType,
	component: function date(props) {
		return <CustomDatePicker {...props} />;
	},
	format: ({ field, record }) => {
		if (!record[field]) return '';
		return moment(record[field]).format('DD-MM-yyyy');
	},
	convertToDb: value => {
		return value;
	},
	convertToForm: value => {
		return value ? moment(value).toISOString() : null;
	}
};
