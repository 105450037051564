import React, { useContext } from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import { ModelForm } from '../components';
import { UserContext } from '../contexts/userContext';

const useStyles = makeStyles(theme => ({
	paper: {
		padding: theme.spacing(2)
	},
	icon: {
		margin: theme.spacing(2)
	}
}));

export default function UserProfile() {
	const { user } = useContext(UserContext);
	const classes = useStyles();
	return (
		<Grid container>
			<Grid item xs={12} sm={12} md={8} lg={6}>
				<Paper className={classes.paper}>
					<Grid container direction="row" alignItems="center">
						<PersonIcon className={classes.icon} color="action" />
						<Typography variant="h6">Personal Information</Typography>
					</Grid>
					<Divider />
					<Grid container spacing={2}>
						<Grid item xs>
							<ModelForm
								recordId={user.id}
								formName={'userProfile'}
								initialMode={'view'}
								hasActions={false}
							/>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</Grid>
	);
}
