const Big = require('big.js');

module.exports = {
	totalPrice: {
		dependentFields: ['invoiceLines'],
		formulate: ({ invoiceLines = [] }) => {
			let sumOfAmount = 0;
			let sumOfValueAddedTax = 0;
			let sumOfWithholdingTax = 0;
			invoiceLines.forEach(invoiceLine => {
				const { amount, valueAddedTax, withholdingTax } = invoiceLine;
				if (amount > 0) {
					sumOfAmount = Big(sumOfAmount)
						.plus(amount)
						.toNumber();
					if (valueAddedTax > 0) {
						sumOfValueAddedTax = Big(valueAddedTax)
							.div(100)
							.mul(amount)
							.plus(sumOfValueAddedTax)
							.toNumber();
					}
					if (withholdingTax > 0) {
						sumOfWithholdingTax = Big(withholdingTax)
							.div(100)
							.mul(amount)
							.plus(sumOfWithholdingTax)
							.toNumber();
					}
				}
			}, 0);
			return Big(sumOfAmount)
				.plus(sumOfValueAddedTax)
				.minus(sumOfWithholdingTax)
				.toNumber(0);
		}
	},
	systemParty: {
		dependentFields: ['totalPrice', 'partyType'],
		formulate: ({ totalPrice, systemParty, partyType }, config) => {
			const {
				invoiceThresholdForClientId,
				receiptThresholdForClientId
			} = config.etaConfig.invoiceConfig;
			const threshold = config.features.etaReceipt
				? receiptThresholdForClientId
				: invoiceThresholdForClientId;
			if (totalPrice >= threshold && partyType === 'client') return true;
			return systemParty;
		}
	},
	receiptStatus: {
		dependentFields: ['partyType'],
		formulate: ({ receiptStatus, invoiceStatus, partyType }, config) => {
			const receiptFiled = ['filed', 'returned'].includes(receiptStatus);
			const invoiceFiled = ['filed'].includes(invoiceStatus);
			const filed = receiptFiled || invoiceFiled;
			if (filed) return receiptStatus;
			if (!config.features.etaReceipt) return receiptStatus;
			if (partyType === 'client') return 'not_filed';
			return null;
		}
	},
	invoiceStatus: {
		dependentFields: ['partyType'],
		formulate: ({ receiptStatus, invoiceStatus, partyType }, config) => {
			const receiptFiled = ['filed', 'returned'].includes(receiptStatus);
			const invoiceFiled = ['filed'].includes(invoiceStatus);
			const filed = receiptFiled || invoiceFiled;
			if (filed) return invoiceStatus;
			const vendor = partyType === 'vendor';
			if (vendor) return 'not_filed';
			if (!config.features.etaReceipt && !vendor) return 'not_filed';
			return null;
		}
	}
};
