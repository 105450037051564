import React from 'react';
import { Switch } from 'formik-material-ui';

function CustomCheckBox(props) {
	const switchProps = {
		form: props.form,
		field: props.field
	};
	return (
		<Switch
			type="checkbox"
			checked={switchProps.field.value ? switchProps.field.value : false}
			{...switchProps}
		/>
	);
}

export default CustomCheckBox;
