import React from 'react';
import { GenericModelTable } from '../components';

const columns = [
	{
		name: 'name',
		label: 'Name',
		options: {
			filter: true,
			sort: true
		}
	},
	{
		name: 'number',
		label: 'Number',
		options: {
			sort: true,
			filter: false,
			searchable: false
		}
	},
	{
		name: 'type',
		label: 'Type',
		options: {
			sort: true,
			filter: true
		}
	},
	{
		name: 'phoneNumber',
		label: 'Phone Number',
		options: {
			sort: false,
			filter: false,
			searchable: false
		}
	},
	{
		name: 'address',
		label: 'Address',
		options: {
			sort: false,
			filter: false,
			searchable: false
		}
	}
];
const modelName = 'store';

function Stores() {
	const tableOptions = {
		modelName: modelName,
		modelNamePlural: 'Stores',
		columns: columns
	};
	return <GenericModelTable {...tableOptions} />;
}

export default Stores;
