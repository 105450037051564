import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import BillTableCell from './BillTableCell';

function BillTableRow({ deleteCell, columns, ...props }) {
	const cellProps = {
		lineIndex: props.lineIndex,
		billLineOptions: props.billLineOptions,
		record: props.record,
		readOnly: props.readOnly
	};
	return (
		<TableRow>
			{columns.map(column => (
				<BillTableCell key={column.field} {...cellProps} {...column} />
			))}
			{deleteCell}
		</TableRow>
	);
}
export default BillTableRow;
