import id from './id';
import React from 'react';
import sharedCurrencyFieldType from '../../shared/field-types/currency';

const idType = 'currency';

export default {
	...sharedCurrencyFieldType,
	component: function currency(props) {
		return <id.component {...props} idType={idType} />;
	},
	format: props => {
		return id.format({ ...props, idType });
	},
	queryParameter: props => {
		return id.queryParameter({ ...props, idType });
	},
	readOnlyComponent: function currency(props) {
		return <id.readOnlyComponent {...props} idType={idType} />;
	}
};
