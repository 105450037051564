import React from 'react';
import MUIDataTable from 'mui-datatables';
import { makeStyles } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';

const useStyles = makeStyles(theme => ({
	root: {
		'& .MuiTableCell-root': {
			textAlign: 'center'
		},
		'& .MuiTableCell-head': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white
		}
	},
	tableRow: {
		'&:nth-of-type(odd)': {
			backgroundColor: indigo[50]
		}
	}
}));

export default function BasicTable({ denseTable, columns, data = [] }) {
	const classes = useStyles();
	const options = {
		customFooter: null,
		customToolbar: null,
		download: false,
		elevation: 2,
		filter: false,
		pagination: false,
		print: false,
		rowHover: false,
		responsive: 'standard',
		search: false,
		selectableRows: 'none',
		sort: false,
		viewColumns: false,
		setTableProps: () => {
			return {
				size: denseTable ? 'small' : 'medium',
				className: classes.root
			};
		},
		setRowProps: () => {
			return { className: classes.tableRow };
		}
	};

	return <MUIDataTable data={data} columns={columns} options={options} />;
}
