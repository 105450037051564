import createAccountForm from './create-account';
import createClientForm from './create-client';
import createCurrencyForm from './create-currency';
import createDropdownForm from './create-dropdown';
import createDropdownItemForm from './create-dropdown-item';
import createItemForm from './create-item';
import createParcelForm from './create-parcel';
import createStoreForm from './create-store';
import createUserForm from './create-user';
import createVendorForm from './create-vendor';
import invoiceLayout from './invoice-layout';
import productBill from './product-bill';
import transactionForm from './transaction';
import userProfile from './user-profile';

const layouts = {
	createAccountForm,
	createClientForm,
	createCurrencyForm,
	createDropdownForm,
	createDropdownItemForm,
	createItemForm,
	createParcelForm,
	createStoreForm,
	createUserForm,
	createVendorForm,
	invoiceLayout,
	productBill,
	transactionForm,
	userProfile
};

export default layouts;
