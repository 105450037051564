import React from 'react';
import Typography from '@material-ui/core/Typography';
import { TextBox } from '../components';
import secretFieldType from '../../shared/field-types/secret';

export default {
	...secretFieldType,
	component: function textbox(props) {
		const componentProps = {
			...props,
			autocomplete: 'off'
		};

		componentProps.type = 'password';

		return <TextBox {...componentProps} />;
	},
	readOnlyComponent: function party() {
		return <Typography></Typography>;
	}
};
