const { pick, find } = require('lodash');
module.exports = {
	name: 'child[]',
	validation(yup, { models, fieldDef, buildSchemaFromFields }) {
		const { childModelName, childFields } = fieldDef.options;
		const { fields: childModelFields } = find(
			models,
			model => model.name === childModelName
		);
		const fields = pick(childModelFields, childFields);
		const schema = yup.array().of(buildSchemaFromFields({ fields }));
		return schema;
	}
};
