const Big = require('big.js').default;

module.exports = {
	cost: {
		dependentFields: ['goldWeight', 'goldPricePerGram', 'manufactoringCost'],
		formulate: ({ goldWeight, goldPricePerGram, manufactoringCost }) => {
			// goldWeight * (goldPricePerGram + manufactoringCost)
			return Big(goldWeight || 0)
				.times(Big(goldPricePerGram || 0).plus(manufactoringCost || 0))
				.toNumber();
		}
	},
	price: {
		dependentFields: ['cost', 'margin'],
		formulate: ({ cost, margin }) => {
			// cost / (1 - margin / 100)
			return Big(cost || 0)
				.div(Big(1).minus(Big(margin || 0).div(100)))
				.toNumber();
		}
	},
	status: {
		dependentFields: [],
		formulate: ({ id, status, existingInventory }) => {
			if (!id && existingInventory) return 'available';
			if (id && status !== 'sold' && existingInventory !== undefined)
				return existingInventory ? 'available' : 'created';
			return id ? status : 'created';
		}
	}
};
