import React from 'react';
import { CustomCheckBox } from '../components';
import sharedBooleanFieldType from '../../shared/field-types/boolean';

const formatData = ({ field, record }) => {
	switch (record[field]) {
		case true:
			return 'Yes';
		default:
			return 'No';
	}
};

export default {
	...sharedBooleanFieldType,
	component: function boolean(props) {
		return <CustomCheckBox {...props} />;
	},
	format: formatData,
	convertToDb: value => {
		if (value !== false && value !== true) return null;
		return value;
	},
	convertToForm(value) {
		return value;
	}
};
