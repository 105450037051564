import React from 'react';
import AccountsTable from '../components/AccountsTable';

const modelName = 'client';

export default {
	model: modelName,
	elements: [
		{
			field: 'firstName',
			display: ({ mode, values }) => {
				//  hides component only when 'view' mode is applied
				if ((mode === 'view' && !values?.id) || mode !== 'view') return true;
				return false;
			}
		},
		{
			field: 'lastName',
			display: ({ mode, values }) => {
				//  hides component only when 'view' mode is applied
				if ((mode === 'view' && !values?.id) || mode !== 'view') return true;
				return false;
			}
		},
		{
			field: 'name',
			display: ({ mode, values }) => {
				//  displays component only when 'view' mode is applied
				if ((mode === 'view' && !values?.id) || mode !== 'view') return false;
				return true;
			}
		},
		{
			field: 'nationalId'
		},
		{
			field: 'phoneNumber'
		},
		{
			field: 'email'
		},
		{
			field: 'streetNumber'
		},
		{
			field: 'street'
		},
		{
			field: 'city'
		},
		{
			field: 'governorate'
		},
		{
			field: 'country'
		},
		{
			component: function accounts({ record, model }) {
				return <AccountsTable record={record} parentType={model} />;
			},
			display: ({ mode, ...values }) => {
				//  hides component when 'create' or 'edit' mode is applied
				if ((mode === 'view' && !values?.id) || mode !== 'view') return false;
				return true;
			}
		}
	]
};
