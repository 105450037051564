module.exports = {
	acl(user, defineAbility) {
		const ability = defineAbility((can, cannot) => {
			if (user.role === 'admin') {
				can('manage', 'Client');
			} else {
				can('manage', 'Client');
				cannot('delete', 'Client');
			}
		});

		return { ability };
	}
};
