module.exports = {
	rules: {
		expenseIsTrue(record) {
			return record.expense === true;
		}
	},
	acl(user, defineAbility, action) {
		const requiresRelation = true;
		const ability = defineAbility(can => {
			if (user.role === 'admin') {
				can('manage', 'Transaction');
			} else {
				// special handling for relationship access list, search for @ucast/sql interpreter to build sql statement
				if (['list', 'search'].includes(action)) {
					can('manage', 'Transaction', {
						'parentRelation_invoice.storeId': { $in: user.storeIds },
						parentType: { $eq: 'invoice' },
						expense: { $eq: false }
					});
				} else {
					can('manage', 'Transaction', {
						invoice_storeId: { $in: user.storeIds },
						parentType: { $eq: 'invoice' },
						expense: { $eq: false }
					});
				}
			}
		});
		return { ability, requiresRelation };
	}
};
