import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PasswordIcon from '@material-ui/icons/VpnKey';
import Divider from '@material-ui/core/Divider';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { UserContext } from './contexts/userContext';
import { useFetch } from './api';
import { useHistory } from 'react-router-dom';

export default function UserMenuItems({ anchorEl, setAnchorEl }) {
	const history = useHistory();
	const { setUserState } = React.useContext(UserContext);
	const [, logoutUser] = useFetch({
		url: 'auth/logout',
		manual: true
	});

	const redirectTo = path => {
		handleClose();
		if (history.location.pathname !== path) {
			history.push(path);
		}
	};
	const handleLogout = () => {
		handleClose();
		logoutUser().then(() => {
			setUserState(null);
		});
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<Menu
			id="menu-appbar"
			anchorEl={anchorEl}
			getContentAnchorEl={null}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={handleClose}
		>
			<MenuItem onClick={() => redirectTo('/profile')}>
				<ListItemIcon>
					<PersonIcon />
				</ListItemIcon>
				<ListItemText primary="Profile" />
			</MenuItem>
			<Divider />
			<MenuItem onClick={() => redirectTo('/change-password')}>
				<ListItemIcon>
					<PasswordIcon />
				</ListItemIcon>
				<ListItemText primary="Change Password" />
			</MenuItem>
			<Divider />
			<MenuItem onClick={handleLogout}>
				<ListItemIcon>
					<ExitToAppIcon />
				</ListItemIcon>
				<ListItemText primary="Sign out" />
			</MenuItem>
		</Menu>
	);
}
