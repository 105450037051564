import React from 'react';
import { Grid } from '@material-ui/core';
import { ModelForm } from '../components';
import { useParams } from 'react-router-dom';

export default function Account({ formMode, parentType }) {
	const { id, childId } = useParams();
	return (
		<Grid container>
			<ModelForm
				recordId={childId}
				parentId={id}
				parentType={parentType}
				formName={'createAccountForm'}
				initialMode={formMode}
			/>
		</Grid>
	);
}
