import React from 'react';
import { GenericModelTable } from '../components';

const columns = [
	{
		name: 'name',
		label: 'Name',
		options: {
			filter: true,
			sort: true
		}
	},
	{
		name: 'number',
		label: 'Number',
		options: {
			sort: true,
			filter: false,
			searchable: false
		}
	},
	{
		name: 'minCarat',
		label: 'Min. Carat',
		options: {
			sort: true,
			filter: false,
			searchable: false
		}
	},
	{
		name: 'maxCarat',
		label: 'Max. Carat',
		options: {
			sort: true,
			filter: false,
			searchable: false
		}
	},
	{
		name: 'caratRange',
		label: 'Carat Range',
		options: {
			sort: true,
			filter: false,
			searchable: false
		}
	},
	{
		name: 'quantity',
		label: 'Quantity',
		options: {
			sort: true,
			filter: false,
			searchable: false
		}
	},

	{
		name: 'cut',
		label: 'Cut',
		options: {
			filter: true,
			sort: true
		}
	},
	{
		name: 'costPerCarat',
		label: 'Cost per Carat',
		options: {
			sort: true,
			filter: false,
			searchable: false
		}
	},
	{
		name: 'pricePerCarat',
		label: 'Price per Carat',
		options: {
			sort: true,
			filter: false,
			searchable: false
		}
	},
	{
		name: 'installationCost',
		label: 'Installation Cost',
		options: {
			sort: true,
			filter: false,
			searchable: false
		}
	},
	{
		name: 'storeId',
		label: 'Store',
		options: {
			filter: true,
			sort: true
		}
	}
];
const modelName = 'parcel';

function Parcels() {
	const tableOptions = {
		modelName,
		modelNamePlural: 'Parcels',
		columns
	};
	return <GenericModelTable {...tableOptions} />;
}

export default Parcels;
