import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { Switch } from 'react-router-dom';

import { useFetch } from '../api';
import { AppContextProvider, createContextData } from '../contexts/appContext';
import { useUserContext } from '../contexts/userContext';
import { CSRFInerceptor, LoadingInterceptor } from '../interceptors';
import { RouteWithSubRoutes } from '../routes';
import { ChangePassword } from '../pages';
import AppHeader from './AppHeader';
import NavigationSideMenu from './NavigationSideMenu';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex'
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh'
	},
	container: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3)
	}
}));

function AppHeaderAndNavMenu() {
	const [open, setOpen] = React.useState(true);
	const expandNavMenu = () => setOpen(true);
	const shrinkNavMenu = () => setOpen(false);
	return (
		<>
			<AppHeader title="El Galla" open={open} onMenuIconClick={expandNavMenu} />
			<NavigationSideMenu open={open} onToolbarIconClick={shrinkNavMenu} />
		</>
	);
}

export default function Main({ routes }) {
	const classes = useStyles();

	const [{ data: appData = {} }] = useFetch({
		url: 'api/context'
	});
	const { user } = useUserContext();
	if (!appData) return <CircularProgress />;

	return (
		<AppContextProvider value={createContextData(appData)}>
			<CSRFInerceptor />
			<div className={classes.root}>
				<CssBaseline />
				<AppHeaderAndNavMenu />
				<main className={classes.content}>
					<div className={classes.appBarSpacer} />
					<LoadingInterceptor />
					<Container maxWidth={false} className={classes.container}>
						{user.isTempPassword ? (
							<ChangePassword />
						) : (
							<Switch>
								{routes(appData).map((route, i) => {
									return (
										<RouteWithSubRoutes key={i} {...route} routes={routes} />
									);
								})}
							</Switch>
						)}
					</Container>
				</main>
			</div>
		</AppContextProvider>
	);
}
