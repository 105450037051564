import React from 'react';
import ids from './ids';
import storesFieldType from '../../shared/field-types/stores';

const configs = {
	model: 'store',
	idsMapper: 'stores'
};
export default {
	...storesFieldType,
	component: function stores(props) {
		return <ids.component {...props} {...configs} />;
	},
	format: props => {
		return ids.format({ ...props, ...configs });
	}
};
