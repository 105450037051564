import React from 'react';
import { useParams } from 'react-router-dom';

export default function PDFInvoiceViewer() {
	const { id } = useParams();
	const pdfFile = `/api/invoice/pdf/${id}`;
	return (
		<object
			style={{ border: 'none', width: '100%', height: '100vh' }}
			type="application/pdf"
			title="pdf document"
			id="print-file"
			data={pdfFile}
		>
			<iframe
				title="iframe Example 1"
				src={pdfFile}
				style={{ border: 'none', width: '100%', height: '100vh' }}
			>
				<p>
					Your browser does not support PDFs.
					<a href={pdfFile}>Click here to download the PDF</a>
				</p>
			</iframe>
		</object>
	);
}
