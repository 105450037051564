import React from 'react';
import { Grid } from '@material-ui/core';
import { ModelForm } from '../components';
import { useParams } from 'react-router-dom';

export default function Client({ formMode }) {
	const { id } = useParams();
	return (
		<Grid container>
			<ModelForm
				recordId={id}
				formName={'createClientForm'}
				initialMode={formMode}
			/>
		</Grid>
	);
}
