import React from 'react';
import { Typography } from '@material-ui/core';
import id from './id';
import partyAccountFieldType from '../../shared/field-types/partyaccount';

const partyAccount = {
	...partyAccountFieldType,
	component: function partyAccount({ ...props }) {
		const parentId = props.form?.values?.partyId;
		if (!parentId) return null;
		return (
			<id.component
				{...props}
				idType="account"
				customLabelField="currency_name"
			/>
		);
	},
	format: props => {
		const { currencies, record } = props;
		const currencyId = record.account_currencyId;
		if (!currencyId) return '';
		const accountCurrency = currencies.find(
			currency => currency.id === currencyId
		);
		return accountCurrency ? accountCurrency.name : '';
	},
	queryParameter: props => {
		return id.queryParameter({
			...props,
			idType: 'account'
		});
	},
	readOnlyComponent: function party(props) {
		const formatProps = {
			currencies: props.currencies,
			record: props.record
		};
		return <Typography>{partyAccount.format(formatProps)}</Typography>;
	}
};

export default partyAccount;
