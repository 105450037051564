import React from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { ModelForm } from '../components';

export default function Dropdownitem({ formMode, parentType }) {
	const { id, childId } = useParams();
	return (
		<Grid container>
			<ModelForm
				recordId={childId}
				parentId={id}
				parentType={parentType}
				submitParentType={false}
				parentIdName={'dropdownId'}
				formName={'createDropdownItemForm'}
				initialMode={formMode}
			/>
		</Grid>
	);
}
