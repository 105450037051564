import id from './id';
import React from 'react';
import sharedInvoiceFieldType from '../../shared/field-types/invoice.js';

const idType = 'invoice';

export default {
	...sharedInvoiceFieldType,
	component: function invoice(props) {
		return <id.component {...props} idType={idType} />;
	},
	format: props => {
		return id.format({ ...props, idType });
	},
	queryParameter: props => {
		return id.queryParameter({ ...props, idType });
	},
	readOnlyComponent: function invoice(props) {
		return <id.readOnlyComponent {...props} idType={idType} />;
	}
};
