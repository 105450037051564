import React from 'react';
import { BasicTable, MultiInput } from '../components';
import parcelsFieldType from '../../shared/field-types/parcels';

const configs = {
	model: 'parcel',
	idsMapper: 'parcels'
};

export default {
	...parcelsFieldType,
	component: function parcels({ field, ...props }) {
		return <MultiInput arrayName={field.name} initialLength={1} {...props} />;
	},
	readOnlyComponent: function parcels({ properties, field, record, models }) {
		if (!record?.[field.name]) return null;
		if (!Array.isArray(record?.[field.name])) return record[field.name];

		const data = record[field.name].map(element => {
			const stoneParcel = record[configs.idsMapper].find(
				({ id }) => id === element.parcelId
			);
			const idField = models[configs.model].identifierFields[0];
			return { ...element, parcelId: stoneParcel?.[idField] };
		});
		return <BasicTable columns={properties} data={data || []} denseTable />;
	},
	convertToDb: value => {
		return !value ? [] : value;
	}
};
