import React from 'react';
import { Invoice, InvoicePayment, FileInvoiceButton } from '../components';

const modelName = 'invoice';

export default {
	model: modelName,
	elements: [
		{
			field: 'invoiceNumber',
			readOnly: true,
			display: ({ values }) => {
				return values.status === 'active';
			}
		},
		{
			field: 'status',
			display: ({ values, mode }) => {
				//After creation
				return values.id && mode === 'view';
			}
		},
		{
			field: 'storeId',
			editable: values => {
				return !values.id;
			}
		},
		{ field: 'partyType', display: ({ user }) => user.role === 'admin' },
		{
			field: 'partyType',
			readOnly: true,
			display: ({ user }) => user.role !== 'admin'
		},
		{
			field: 'systemParty',
			display: ({ values }) => values.partyType === 'client',
			editable: (values, config) => {
				const {
					invoiceThresholdForClientId,
					receiptThresholdForClientId
				} = config.etaConfig.invoiceConfig;
				const threshold = config.features.etaReceipt
					? receiptThresholdForClientId
					: invoiceThresholdForClientId;
				return values.totalPrice < threshold;
			}
		},
		{
			field: 'partyName',

			display: ({ values }) =>
				values.partyType === 'client' && values.systemParty !== true
		},
		{
			field: 'partyId',
			dependentFields: ['partyType'],
			display: ({ values }) =>
				(values.partyType == 'client' && values.systemParty === true) ||
				values.partyType === 'vendor'
		},
		{
			field: 'partyAccountId',
			dependentFields: ['partyId'],
			display: ({ values }) =>
				(values.partyType === 'client' && values.systemParty === true) ||
				values.partyType === 'vendor'
		},
		{ field: 'invoiceDate' },
		{ field: 'invoiceReference' },
		{
			field: 'etaReceiptStatus',
			readOnly: true,
			display: ({ values, mode, user, config }) => {
				const admin = user.role === 'admin';
				const viewMode = mode === 'view';
				if (config.features.etaReceipt) {
					const client = values.partyType === 'client';
					return values.id && viewMode && client && admin;
				}
				return false;
			}
		},
		{
			field: 'etaInvoiceStatus',
			readOnly: true,
			display: ({ values, mode, user, config }) => {
				const admin = user.role === 'admin';
				const viewMode = mode === 'view';
				if (config.features.etaReceipt) {
					const vendor = values.partyType === 'vendor';
					return values.id && viewMode && vendor && admin;
				}
				return values.id && viewMode && admin;
			}
		},
		{
			field: 'etaInvoiceId',
			component: FileInvoiceButton,
			display: ({ values, mode, user }) =>
				values.id && mode === 'view' && user.role === 'admin'
		},
		{
			field: 'invoiceLines',
			width: 12,
			component: function invoice(props) {
				return <Invoice {...props} />;
			},
			modelName,
			invoiceLineOptions: {
				modelName: 'invoiceLine',
				quantityField: 'quantity',
				priceField: 'price',
				amountField: 'amount',
				discountField: 'discount',
				valueAddedTaxField: 'valueAddedTax',
				withholdingTaxField: 'withholdingTax',
				invoiceLinesField: 'invoiceLines',
				columns: [
					{
						field: 'itemId'
					},
					{
						field: 'quantity'
					},
					{
						field: 'valueAddedTax'
					},
					{
						field: 'withholdingTax'
					},
					{
						field: 'amount'
					},
					{
						field: 'total',
						readOnly: true
					}
				]
			}
		},
		{
			component: function invoicePayment(props) {
				return <InvoicePayment {...props} />;
			},
			display: ({ values }) => values.id,
			width: 12
		}
	]
};
