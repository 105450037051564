import React from 'react';
import { DialogContent, Grid } from '@material-ui/core';
import { ModelForm, AlertDialog } from '../components';
import { useParams } from 'react-router-dom';

import api from '../api';
import { TextBox } from '../components';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import PasswordIcon from '@material-ui/icons/VpnKey';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications';

const useStyles = makeStyles(theme => ({
	paper: {
		minWidth: '250px',
		maxWidth: '350px',
		width: '40%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: theme.spacing(3)
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
		width: '25px',
		height: '25px',
		border: `2px solid ${theme.palette.background.paper}`
	},
	form: {
		'& .MuiTextField-root': {
			marginTop: theme.spacing(2)
		},
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	button: {
		margin: theme.spacing(5, 0, 2)
	},
	largeLogo: {
		width: theme.spacing(10),
		height: theme.spacing(10)
	},
	fieldIcon: {
		marginRight: theme.spacing(1)
	}
}));

export default function Store({ formMode }) {
	const { id } = useParams();
	const [state, setState] = React.useState({
		confirmDialogOpen: false,
		initialValues: {
			posClientSecret: '',
			posClientId: '',
			posOsVersion: '',
			posSerial: ''
		},
		form: null
	});
	const classes = useStyles();
	const { addToast } = useToasts();

	async function handleSetPos(values) {
		return api.post(`/api/store/${id}/pos`, values).then(response => {
			addToast('Successfully set POS', {
				appearance: 'success',
				autoDismiss: true
			});
			state.form.setValues({
				...state.form.values,
				posSecret: null,
				posClientId: response.data.posClientId,
				posOsVersion: response.data.posOsVersion,
				posSerial: response.data.posSerial
			});
			setState({ ...state, confirmDialogOpen: false });
		});
	}
	return (
		<Grid container>
			<ModelForm
				recordId={id}
				formName={'createStoreForm'}
				initialMode={formMode}
				customActionButtons={[
					{
						text: 'Set POS Secret',
						color: 'primary',
						display(form) {
							return form.values.id && form.mode === 'view';
						},
						onClick(form) {
							setState({
								...state,
								initialValues: {
									posSerial: form.values.posSerial,
									posClientId: form.values.posClientId,
									posOsVersion: form.values.posOsVersion
								},
								form,
								confirmDialogOpen: true
							});
						}
					}
				]}
			/>
			<Formik
				validationSchema={Yup.object({
					posClientSecret: Yup.string().required('Required'),
					posOsVersion: Yup.string().required('Required'),
					posClientId: Yup.string().required('Required'),
					posSerial: Yup.string().required('Required')
				})}
				enableReinitialize={true}
				initialValues={state.initialValues}
				onSubmit={handleSetPos}
			>
				{({ submitForm }) => (
					<AlertDialog
						title={'Set POS Secret'}
						open={state.confirmDialogOpen}
						setOpen={value => setState({ ...state, confirmDialogOpen: value })}
						confirmationText="Save"
						cancellationText="cancel"
						confirmationOnClick={submitForm}
						confirmationColor="primary"
						providingContent={true}
					>
						<DialogContent>
							<Form className={classes.form}>
								<Grid container direction="column">
									<Field
										name="posSerial"
										label="ETA POS Serial Number"
										component={TextBox}
										required={true}
									/>
									<Field
										name="posOsVersion"
										label="ETA POS OS Version"
										component={TextBox}
										required={true}
									/>
									<Field
										name="posClientId"
										label="ETA POS Client Id"
										component={TextBox}
										required={true}
									/>
									<Field
										name="posClientSecret"
										label="ETA POS Secret"
										component={TextBox}
										required={true}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<PasswordIcon
														color="action"
														className={classes.fieldIcon}
													/>
												</InputAdornment>
											),
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														//	onClick={() => setShowPassword(!showPassword)}
														edge="end"
													>
														{/* {showPassword ? <Visibility /> : <VisibilityOff />} */}
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
								</Grid>
							</Form>
						</DialogContent>
					</AlertDialog>
				)}
			</Formik>
		</Grid>
	);
}
