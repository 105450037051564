import { Button, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import api from '../api';
import { DropzoneDialog } from 'material-ui-dropzone';
import UploadIcon from '@material-ui/icons/Backup';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { saveAs } from 'file-saver';
import filenameReservedRegex from 'filename-reserved-regex';
import { useToasts } from 'react-toast-notifications';
import { useAppData } from '../contexts/appContext';
import fileFieldType from '../../shared/field-types/file';

export default {
	...fileFieldType,
	component: function file(props) {
		const {
			form: { setFieldValue },
			field
		} = props;

		const appData = useAppData();
		const [state, setState] = useState({});
		const handleClose = () => {
			setState({
				...state,
				open: false
			});
		};
		const { addToast } = useToasts();

		const handleSave = files => {
			const file = files[0];
			if (
				filenameReservedRegex().test(file.name) || // invalid chars check
				!/^[^.]+\.[^.]+$/.test(file.name) // multiple dots check
			) {
				addToast('File name invalid, please rename the file and try again', {
					appearance: 'error',
					autoDismiss: true
				});
				setFieldValue(field.name, '');
				setState({});
				return;
			}
			let formData = new FormData();
			formData.append('file', file);
			formData.append('fileName', file.name);
			formData.append('size', file.size);
			formData.append('metaData', JSON.stringify({ fsName: file.name }));
			if (field.value && field.value.id) formData.append('id', field.value.id);

			api.post('/api/file/upload', formData).then(response => {
				const values = response.data;
				if (values) {
					setFieldValue(field.name, values);
					addToast('File uploaded successfully!', {
						appearance: 'success',
						autoDismiss: true
					});
				}
			});
			setState({
				file,
				open: false
			});
		};

		const handleOpen = () => {
			setState({ ...state, open: true });
		};
		const handleDelete = () => {
			setFieldValue(field.name, '');
			setState({});
		};
		return (
			<div>
				{field.value ? <Typography>{field.value.fileName} </Typography> : ''}
				<Button
					variant="contained"
					color="primary"
					startIcon={<UploadIcon />}
					onClick={handleOpen}
				>
					{field.value ? 'Edit File' : 'Add File'}
				</Button>
				{field.value ? (
					<Button
						variant="contained"
						color="secondary"
						startIcon={<DeleteIcon />}
						onClick={handleDelete}
					>
						Delete File
					</Button>
				) : (
					<></>
				)}
				<DropzoneDialog
					open={state.open}
					onSave={handleSave}
					maxFileSize={appData?.config.fileStorage.maxFileSize}
					acceptedFiles={appData?.config.fileStorage.acceptableExtensions} // invalid extension check
					filesLimit={1}
					showFileNamesInPreview={true}
					showPreviews={true}
					showAlerts={['error', 'info']}
					onClose={handleClose}
				/>
			</div>
		);
	},
	format: ({ field, record }) => {
		return record[field];
	},
	readOnlyComponent: function file(props) {
		const { field } = props;
		const handleDownload = () => {
			saveAs(`/api/file/download/${field.value.id}`);
		};

		return field.value && field.value.id ? (
			<>
				<Typography>{field.value.fileName} </Typography>
				<Button
					variant="contained"
					color="primary"
					startIcon={<DownloadIcon />}
					onClick={handleDownload.bind(this)}
				>
					Download File
				</Button>
			</>
		) : (
			' - '
		);
	}
};
