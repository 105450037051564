import React from 'react';
import { TextField } from 'formik-material-ui';
function TextBox(props) {
	const { type, label, form, field } = props;
	const textFieldProps = { label, type, form, field };
	if (type === 'decimal')
		return <TextField {...textFieldProps} type="number" />;
	if (type === 'password')
		return <TextField {...textFieldProps} type="password" />;
	return <TextField {...textFieldProps} />;
}

export default TextBox;
