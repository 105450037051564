import React from 'react';
import { DropdownItemsTable } from '../components';

const modelName = 'dropdown';

export default {
	model: modelName,
	elements: [
		{
			field: 'name'
		},
		{
			field: 'static'
		},
		{
			field: 'sortByRank'
		},
		{
			component: function dropdownItems({ record, model }) {
				return <DropdownItemsTable parent={record} model={model} />;
			},
			display: ({ mode, values }) => {
				return values.id && mode === 'view';
			}
		}
	]
};
