const modelName = 'currency';

export default {
	model: modelName,
	elements: [
		{
			field: 'name'
		},
		{
			field: 'symbol'
		},
		{
			field: 'default'
		}
	]
};
