module.exports = {
	acl(user, defineAbility) {
		const ability = defineAbility((can, cannot) => {
			if (user.role === 'admin') {
				cannot('manage', 'Auditlog');
				can('read', 'Auditlog');
			} else {
				cannot('manage', 'Auditlog');
			}
		});

		return { ability };
	}
};
