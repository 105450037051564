import React from 'react';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import Main from '../components/Main';
import PDFInvoiceViewer from '../components/PDFInvoiceViewer';
import pages from '../pages';

const routedModels = [
	{ model: 'client', prefix: '' },
	{ model: 'currency', prefix: '/settings' },
	{ model: 'bill', prefix: '' },
	{ model: 'dropdown', prefix: '/settings' },
	{ model: 'invoice', prefix: '' },
	{ model: 'item', prefix: '' },
	{ model: 'parcel', prefix: '' },
	{ model: 'store', prefix: '/settings' },
	{ model: 'user', prefix: '/settings' },
	{ model: 'vendor', prefix: '' }
];

const redirectToLogin = () => () => (
	<Redirect
		to={{
			pathname: '/login',
			state: { from: window.location.pathname }
		}}
	/>
);

const makeRoutes = models => {
	const transactionModels = ['client', 'store', 'vendor'];

	let routes = [];

	let TransactionPage = pages['Transaction'];
	transactionModels.forEach(model => {
		const routePrefix = getRoutePrefix(model);
		routes = [
			...routes,
			{
				//VIEW
				path: `${routePrefix}/${models[model].name}/:id/account/:childId/transaction/:transactionId`,
				exact: true,
				component: () => (() => <TransactionPage formMode="view" />)(),
				breadcrumb: { name: 'Transaction' }
			}
		];
	});
	routedModels.forEach(({ model, prefix }) => {
		const { name: modelName, pluralName: modelPluralName } = models[model];
		let ModelPage = pages[_.capitalize(modelName)];
		let ModelListPage = pages[_.capitalize(modelPluralName)];
		if (models[model].childModels.length > 0) {
			models[model].childModels.forEach(child => {
				let ChildPage = pages[_.capitalize(child)];
				routes = [
					...routes,
					{
						//EDIT
						path: `${prefix}/${modelName}/:id/${child}/:childId/edit`,
						exact: true,
						component: () =>
							(() => <ChildPage parentType={modelName} formMode="edit" />)()
					},
					{
						//CREATE
						path: `${prefix}/${modelName}/:id/${child}`,
						exact: true,
						component: () => (() => <ChildPage parentType={modelName} />)(),
						breadcrumb: { name: _.capitalize(child) }
					},
					{
						//VIEW
						path: `${prefix}/${modelName}/:id/${child}/:childId`,
						exact: true,
						component: () =>
							(() => <ChildPage parentType={modelName} formMode="view" />)(),
						breadcrumb: {
							name: `${_.capitalize(child)} Info`,
							pathRef: `${prefix}/${modelName}/:id/${child}/:childId`
						}
					}
				];
			});
		}

		routes = [
			...routes,
			{
				//EDIT
				path: `${prefix}/${modelName}/:id/edit`,
				exact: true,
				component: () => (() => <ModelPage formMode="edit" />)()
			},
			{
				//CREATE
				path: `${prefix}/${modelName}`,
				exact: true,
				component: ModelPage,
				breadcrumb: {
					name: _.capitalize(modelPluralName),
					pathRef: `${prefix}/${modelPluralName}`
				}
			},
			{
				//LIST
				path: `${prefix}/${models[model].pluralName}`,
				exact: true,
				component: ModelListPage,
				breadcrumb: {
					name: _.capitalize(modelPluralName),
					pathRef: `${prefix}/${modelPluralName}`
				}
			},
			{
				//VIEW
				path: `${prefix}/${modelName}/:id`,
				exact: true,
				component: () => (() => <ModelPage formMode="view" />)(),
				breadcrumb: {
					name: `${_.capitalize(modelName)} Info`,
					pathRef: `${prefix}/${modelName}/:id`
				}
			}
		];
	});
	return routes;
};

const routes = user => {
	return [
		{
			path: '/login',
			exact: true,
			component: pages['Login']
		},
		{
			path: '/invoice/download/:id',
			exact: true,
			component: user ? PDFInvoiceViewer : redirectToLogin()
		},
		{
			path: '/',
			component: user ? Main : redirectToLogin(),
			routes: appData => {
				return [
					...makeRoutes(appData.models),
					{
						path: '/profile',
						exact: true,
						component: pages['UserProfile'],
						breadcrumb: { name: 'Profile' }
					},
					{
						path: '/change-password',
						exact: true,
						component: pages['ChangePassword'],
						breadcrumb: { name: 'Change Password' }
					},
					{
						path: '/expenses',
						exact: true,
						component: pages['Expenses'],
						breadcrumb: { name: 'Expenses' }
					},
					{
						path: '/expense',
						exact: true,
						component: pages['Expense'],
						breadcrumb: { name: 'Expense', pathRef: '/expenses' }
					},
					{
						path: '/expense/:id/edit',
						exact: true,
						component: () => (() => <pages.Expense formMode="edit" />)()
					},
					{
						path: '/expense/:id',
						exact: true,
						component: () => (() => <pages.Expense formMode="view" />)(),
						breadcrumb: { name: 'Expense Info', pathRef: '/expense/:id' }
					},
					{
						path: '/settings/user-activity',
						exact: true,
						component: pages['UserActivity'],
						breadcrumb: { name: 'Users Activity' }
					},
					{
						path: '/settings/invoice-packages',
						exact: true,
						component: pages['InvoicePackages'],
						breadcrumb: { name: 'Invoice Packages' }
					},
					{
						path: '/',
						exact: true,
						component: pages['Dashboard'],
						breadcrumb: { name: 'Dashboard', pathRef: '/' }
					},
					{
						path: '*',
						component: pages['PageNotFound']
					}
				];
			}
		}
	];
};

export const getRoutePrefix = modelName => {
	return routedModels.find(value => value.model === modelName)?.prefix;
};

export default routes;
