import { useLayoutEffect, useState } from 'react';
import api from '../api';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';

function ErrorInterceptor() {
	const [errorInterceptor, setErrorInterceptor] = useState();
	const { addToast } = useToasts();

	const addErrorInterceptor = () => {
		const interceptor = api.interceptors.response.use(
			res => res,
			err => {
				if (err.message === 'Network Error') {
					addToast('Unable to reach server', {
						appearance: 'error',
						autoDismiss: 'false'
					});
				}
				//temporary logic of when to throw a toast
				else if (
					!axios.isCancel(err) &&
					[502, 500, 401, 403, 400, 423].includes(err.response?.status)
				) {
					addToast(err.response.data, {
						appearance: 'error',
						autoDismiss: false
					});
				} else {
					addToast('Unknown error occurred', {
						appearance: 'error',
						autoDismiss: false
					});
				}
				throw err;
			}
		);
		setErrorInterceptor(interceptor);
	};

	const removeErrorInterceptor = () => {
		api.interceptors.response.eject(errorInterceptor);
		setErrorInterceptor();
	};

	useLayoutEffect(() => {
		addErrorInterceptor();
		return () => {
			removeErrorInterceptor();
		};
	}, [addToast]);

	return null;
}

export default ErrorInterceptor;
