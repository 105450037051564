module.exports = {
	name: 'jsonarray',
	validation(yup, { fieldDef, buildSchemaFromFields }) {
		const objectSchema = fieldDef.properties
			? buildSchemaFromFields({ fields: fieldDef.properties })
			: yup.object();
		let fieldSchema = yup
			.array()
			.of(objectSchema)
			.min(1);
		return fieldSchema;
	}
};
