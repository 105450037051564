import React from 'react';
import { Grid } from '@material-ui/core';
import { ModelForm } from '../components';
import { useParams } from 'react-router-dom';
import { UserContext } from '../contexts/userContext';

export default function Parcel({ formMode }) {
	const { id } = useParams();
	const { user } = React.useContext(UserContext);
	let defaultValues = {};
	if (!id) {
		defaultValues = {
			storeId: user.selectedStore
		};
	}
	return (
		<Grid container>
			<ModelForm
				recordId={id}
				formName={'createParcelForm'}
				defaultValues={defaultValues}
				initialMode={formMode}
			/>
		</Grid>
	);
}
