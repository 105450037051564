import React from 'react';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FaceIcon from '@material-ui/icons/Face';
import PeopleIcon from '@material-ui/icons/People';
import StoreIcon from '@material-ui/icons/Store';
import ItemIcon from '@material-ui/icons/ShoppingBasket';
import VendorIcon from '@material-ui/icons/Storefront';
import SettingsIcon from '@material-ui/icons/Settings';
import UserActivityIcon from '@material-ui/icons/LocalActivity';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InvoiceIcon from '@material-ui/icons/Receipt';
import CurrencyIcon from '@material-ui/icons/LocalAtm';
import ViewListIcon from '@material-ui/icons/ViewList';
import ArchiveIcon from '@material-ui/icons/Archive';
import { useUserContext } from './contexts/userContext';
import { useAppData } from './contexts/appContext';
import { defineAbility } from '@casl/ability';

const mainListItems = [
	{
		name: 'Dashboard',
		link: '/',
		icon: DashboardIcon
	},
	{
		name: 'Items',
		link: '/items',
		icon: ItemIcon,
		modelName: 'item'
	},
	{
		name: 'Clients',
		link: '/clients',
		icon: FaceIcon,
		modelName: 'client'
	},
	{
		name: 'Vendors',
		link: '/vendors',
		icon: VendorIcon,
		modelName: 'vendor'
	},
	{
		name: 'Invoices',
		link: '/invoices',
		icon: InvoiceIcon,
		modelName: 'invoice'
	},
	{
		name: 'Bills',
		link: '/bills',
		icon: InvoiceIcon,
		modelName: 'bill'
	},
	{
		name: 'Settings',
		icon: SettingsIcon,
		display: user => user.role === 'admin',
		items: [
			{
				name: 'User Activity',
				link: '/settings/user-activity',
				icon: UserActivityIcon,
				modelName: 'auditlog'
			},
			{
				name: 'Invoice Packages',
				link: '/settings/invoice-packages',
				icon: ArchiveIcon,
				modelName: 'invoicePackage'
			},
			{
				name: 'Currencies',
				link: '/settings/currencies',
				icon: CurrencyIcon,
				modelName: 'currency'
			},
			{
				name: 'Dropdowns',
				link: '/settings/dropdowns',
				icon: ViewListIcon,
				modelName: 'dropdown'
			},
			{
				name: 'Stores',
				link: '/settings/stores',
				icon: StoreIcon,
				modelName: 'store'
			},
			{
				name: 'Users',
				link: '/settings/users',
				icon: PeopleIcon,
				modelName: 'user'
			}
		]
	}
];

const useStyles = level =>
	makeStyles(theme => ({
		nested: {
			paddingLeft: theme.spacing(4 + level * 2)
		},
		menuItem: {
			'&.active': {
				background: theme.palette.action.selected
			}
		}
	}));

export function MainListItems() {
	return (
		<List component="nav" disablePadding>
			{mainListItems.map((item, index) => (
				<NavListItem {...item} key={index} />
			))}
		</List>
	);
}

const Link = (props, ref) => <NavLink exact {...props} innerRef={ref} />;
const BaseListItem = ({ onClick, link, children, ...otherProps }) => {
	const newProps = link
		? { to: link, component: React.forwardRef(Link) }
		: { onClick: onClick };
	return (
		<ListItem {...otherProps} {...newProps}>
			{children}
		</ListItem>
	);
};

function aclCheck(model, user) {
	const { ability } = model.acl ? model.acl(user, defineAbility) : {};
	if (ability) return ability.can('read', _.capitalize(model.name));
	return true;
}

const NavListItem = props => {
	const {
		className,
		icon: Icon,
		items = [],
		link,
		name,
		level = 0,
		display,
		modelName
	} = props;
	const classes = useStyles(level)();
	const { user } = useUserContext();
	const { models } = useAppData();
	const [open, setOpen] = React.useState(false);
	const isExpandable = items && items.length > 0;
	const shouldDisplay = display ? display(user) : true;
	const canRead = modelName ? aclCheck(models[modelName], user) : true;
	if (!shouldDisplay || !canRead) return <></>;
	return (
		<>
			<BaseListItem
				button
				className={`${classes.menuItem} ${className}`}
				link={link}
				onClick={() => setOpen(!open)}
			>
				{Icon && (
					<ListItemIcon>
						<Icon />
					</ListItemIcon>
				)}
				<ListItemText primary={name} inset={!Icon} />
				{isExpandable && !open && <ExpandMoreIcon />}
				{isExpandable && open && <ExpandLessIcon />}
			</BaseListItem>
			{/* SubList Item */}
			{isExpandable && (
				<Collapse in={open} timeout="auto" unmountOnExit>
					<Divider />
					<List component="div" disablePadding>
						{items.map((item, index) => (
							<NavListItem
								{...item}
								key={index}
								className={classes.nested}
								level={level + 1}
							/>
						))}
					</List>
				</Collapse>
			)}
		</>
	);
};
