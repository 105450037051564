import React from 'react';
import api from '../api';
import { Grid } from '@material-ui/core';
import { VpnKey, LockOpen } from '@material-ui/icons';
import { ModelForm } from '../components';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useUserContext } from '../contexts/userContext';

export default function User({ formMode }) {
	const { id } = useParams();
	const { addToast } = useToasts();
	const { user } = useUserContext();
	const customActionButtons = [];
	if (user.role === 'admin') {
		customActionButtons.push({
			text: 'Reset Password',
			startIcon: <VpnKey />,
			color: 'primary',
			display(form) {
				return form.values.id && form.mode === 'view';
			},
			onClick() {
				api.post(`/api/user/${id}/reset-password`).then(() => {
					addToast('A new password has been emailed to the user', {
						appearance: 'success',
						autoDismiss: true
					});
				});
			}
		});
		customActionButtons.push({
			text: 'Unlock User',
			startIcon: <LockOpen />,
			color: 'primary',
			display(form) {
				return form.values.locked === true && form.mode === 'view';
			},
			onClick: form => {
				api.post(`/api/user/${id}/unlock`).then(() => {
					form.setFieldValue('locked', false);
					addToast('User unlocked.', {
						appearance: 'success',
						autoDismiss: true
					});
				});
			}
		});
	}
	return (
		<Grid container>
			<ModelForm
				recordId={id}
				formName={'createUserForm'}
				initialMode={formMode}
				customActionButtons={customActionButtons}
				formFlags={[
					{
						display({ values, mode, user }) {
							return (
								values.locked === true &&
								user.role === 'admin' &&
								mode === 'view'
							);
						},
						text: 'Locked',
						color: 'secondary'
					}
				]}
			/>
		</Grid>
	);
}
