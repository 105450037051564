class Rules {
	constructor(opts = {}) {
		this.rules = opts.rules;
		this.config = opts.config;
	}

	processRules(record) {
		const processedRules = {};
		const rules = Object.keys(this.rules);
		rules.forEach(ruleKey => {
			const rule = this.rules[ruleKey];
			processedRules[ruleKey] = rule(record, this.config);
		});
		return processedRules;
	}
}
module.exports = Rules;
