module.exports = {
	name: 'parcels',
	validation(yup, fieldDef, fieldTypes) {
		const { properties = [] } = fieldDef;
		const objectSchema = {};
		properties.forEach(property => {
			const fieldType = fieldTypes[property.type];
			objectSchema[property.name] = fieldType
				.validation(yup)
				.required()
				.label(property.label);
		});
		return yup.array().of(yup.object().shape(objectSchema));
	}
};
