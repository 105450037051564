import React from 'react';
import { Bill, BillPayment } from '../components';

const modelName = 'bill';

export default {
	model: modelName,
	elements: [
		{
			field: 'storeId'
		},
		{
			field: 'billNumber',
			readOnly: true,
			display: ({ values }) => {
				return values.status === 'active';
			}
		},
		{ field: 'partyType' },
		{ field: 'partyId', dependentFields: ['partyType'] },
		{ field: 'partyAccountId', dependentFields: ['partyId'] },
		{ field: 'billReference' },
		{
			field: 'billLines',
			width: 12,
			component: Bill,
			modelName,
			billLineOptions: {
				modelName: 'billLine',
				quantityField: 'quantity',
				priceField: 'price',
				amountField: 'amount',
				billLinesField: 'billLines',
				columns: [
					{
						field: 'itemId'
					},
					{
						field: 'quantity'
					},
					{
						field: 'amount'
					}
				]
			}
		},
		{
			component: function billPayment(props) {
				return <BillPayment {...props} />;
			},
			display: ({ values }) => values.id,
			width: 12
		}
	]
};
