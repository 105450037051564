import React from 'react';
import { GenericModelTable } from '../components';

const columns = [
	{
		name: 'name',
		label: 'Name',
		options: {
			filter: true,
			sort: true
		}
	},
	{
		name: 'email',
		label: 'Email',
		options: {
			filter: true,
			sort: false
		}
	},
	{
		name: 'phoneNumber',
		label: 'Phone Number',
		options: {
			filter: false,
			searchable: false,
			sort: false
		}
	},
	{
		name: 'address',
		label: 'Address',
		options: {
			filter: false,
			searchable: false,
			sort: false
		}
	}
];
const modelName = 'client';

function Clients() {
	const tableOptions = {
		modelName,
		modelNamePlural: 'Clients',
		columns
	};
	return <GenericModelTable {...tableOptions} />;
}

export default Clients;
