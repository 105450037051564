import id from './id';
import React from 'react';
import itemModelFieldType from '../../shared/field-types/itemModel';

const idType = 'itemmodel';

export default {
	...itemModelFieldType,
	component: function itemModel(props) {
		return <id.component {...props} idType={idType} />;
	},
	format: props => {
		return id.format({ ...props, idType });
	},
	queryParameter: props => {
		return id.queryParameter({ ...props, idType });
	},
	readOnlyComponent: function itemModel(props) {
		return <id.readOnlyComponent {...props} idType={idType} />;
	}
};
