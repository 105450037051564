import React from 'react';
import { GenericModelTable } from '../components';

const columns = [
	{
		name: 'itemCode',
		label: 'Item Name',
		options: {
			filter: true,
			sort: true
		}
	},
	{
		name: 'description',
		label: 'Description',
		options: {
			filter: true,
			sort: true
		}
	},
	{
		name: 'category',
		label: 'Category',
		options: {
			filter: true,
			sort: true
		}
	},
	{
		name: 'type',
		label: 'Type',
		options: {
			sort: true,
			filter: true
		}
	},
	{
		name: 'goldKarat',
		label: 'Gold Karat',
		options: {
			sort: true,
			filter: false
		}
	},
	{
		name: 'goldWeight',
		label: 'Gold Weight',
		options: {
			sort: false,
			filter: true
		}
	}
];
const modelName = 'item';

function Items() {
	const tableOptions = {
		modelName,
		modelNamePlural: 'Items',
		columns: columns
	};
	return <GenericModelTable {...tableOptions} />;
}

export default Items;
