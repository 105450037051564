import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';

import { UserContext } from '../contexts/userContext';

import UserMenuItems from '../UserMenuItems';

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
	toolbar: {
		paddingRight: 24 // keep right padding when drawer closed
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	menuButton: {
		marginRight: 36
	},
	menuButtonHidden: {
		display: 'none'
	},
	title: {
		flexGrow: 1
	},
	avatarColor: {
		color: theme.palette.secondary.contrastText,
		backgroundColor: theme.palette.secondary.main
	},
	storeSelect: { width: '200px', background: 'white' }
}));

export default function AppHeader({ title = '', onMenuIconClick, open }) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const { user } = React.useContext(UserContext);
	return (
		<AppBar
			position="absolute"
			className={clsx(classes.appBar, open && classes.appBarShift)}
		>
			<Toolbar className={classes.toolbar}>
				<IconButton
					edge="start"
					color="inherit"
					aria-label="open drawer"
					onClick={onMenuIconClick}
					className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
				>
					<MenuIcon />
				</IconButton>
				<Typography
					component="h1"
					variant="h6"
					color="inherit"
					noWrap
					className={classes.title}
				>
					{title}
				</Typography>
				<div>
					<IconButton
						onClick={event => setAnchorEl(event.currentTarget)}
						color="inherit"
					>
						<Avatar className={classes.avatarColor}>
							{user.firstName[0] + user.lastName[0]}
						</Avatar>
					</IconButton>
					<UserMenuItems anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
				</div>
			</Toolbar>
		</AppBar>
	);
}
