import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import DraftIcon from '@material-ui/icons/SaveOutlined';
import { useAppData } from '../contexts/appContext';

const useStyles = makeStyles(theme => ({
	actionButton: {
		margin: theme.spacing(1),
		width: '150px'
	}
}));

function ActionButtons({
	disabled = false,
	onEdit = null,
	onDelete = null,
	onSave = null,
	onSaveDraft = null,
	onCancel = null,
	saveText = 'Save',
	saveDraftText = 'Save as Draft',
	editText = 'Edit',
	deleteText = 'Delete',
	cancelText = 'Cancel',
	customButtons = null,
	form
}) {
	const classes = useStyles();
	const { config } = useAppData();
	return (
		<>
			{onEdit && (
				<Button
					variant="contained"
					disabled={disabled}
					onClick={onEdit}
					className={classes.actionButton}
					startIcon={<EditIcon />}
				>
					{editText}
				</Button>
			)}
			{onDelete && (
				<Button
					variant="contained"
					color="secondary"
					disabled={disabled}
					onClick={onDelete}
					className={classes.actionButton}
					startIcon={<DeleteIcon />}
				>
					{deleteText}
				</Button>
			)}
			{onSave && (
				<Button
					variant="contained"
					color="primary"
					onClick={onSave}
					disabled={disabled}
					className={classes.actionButton}
					startIcon={<SaveIcon />}
				>
					{saveText}
				</Button>
			)}
			{onSaveDraft && (
				<Button
					variant="contained"
					color="primary"
					onClick={onSaveDraft}
					disabled={disabled}
					className={classes.actionButton}
					startIcon={<DraftIcon />}
				>
					{saveDraftText}
				</Button>
			)}
			{onCancel && (
				<Button
					variant="contained"
					onClick={onCancel}
					disabled={disabled}
					className={classes.actionButton}
					startIcon={<CancelIcon />}
				>
					{cancelText}
				</Button>
			)}
			{customButtons &&
				customButtons.map((buttonProps, rowIndex) => {
					const { text, display, ...otherProps } = buttonProps;
					return display(form, config) ? (
						<Button
							key={rowIndex}
							variant="contained"
							className={classes.actionButton}
							{...otherProps}
							disabled={disabled || buttonProps.disabled}
						>
							{text}
						</Button>
					) : null;
				})}
		</>
	);
}

export default ActionButtons;
