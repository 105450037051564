import React from 'react';
import { Grid } from '@material-ui/core';
import { ModelForm } from '../components';
import { useParams } from 'react-router-dom';

export default function Bill({ formMode }) {
	const { id } = useParams();

	return (
		<Grid container>
			<ModelForm
				recordId={id}
				formName={'productBill'}
				initialMode={formMode}
				isDraftable={true}
			/>
		</Grid>
	);
}
