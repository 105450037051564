import {
	List,
	ListItem,
	ListItemText,
	ListSubheader,
	Divider,
	ListItemIcon,
	ListItemSecondaryAction,
	IconButton
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';

import { Add as AddIcon } from '@material-ui/icons';
import React, { useState } from 'react';
import api, { useFetch } from '../api';
import { useHistory } from 'react-router-dom';
import { useAppData } from '../contexts/appContext';
import { AlertDialog } from '.';

const useStyles = makeStyles(theme => ({
	list: {
		borderStyle: 'solid',
		borderRadius: 5,
		borderColor: 'lightgray',
		borderWidth: 1.5,
		width: '230px',
		margin: theme.spacing(1)
	},
	listHeader: {
		fontSize: 18,
		color: '#4d4d4d'
	}
}));

function FormTable({
	type,
	parent,
	title,
	model,
	submitParentType = true,
	parentIdName = 'parentId',
	hasActions = true
}) {
	const [{ data }, refetch] = useFetch({
		url: `/api/${type}?${parentIdName}=${parent.id}${
			submitParentType ? `&parentType=${model}` : ''
		}`
	});
	const [deleteItem, setDeleteItem] = useState(null);

	const classes = useStyles();
	const history = useHistory();
	const appData = useAppData();
	const idField = appData.models[type].identifierFields[0];
	const handleClick = item => {
		history.push(`${history.location.pathname}/${type}/${item.id}`);
	};
	const handleDeleteClick = item => {
		setDeleteItem(item);
	};
	const onDeleteConfirmed = () => {
		api.delete(`/api/${type}/${deleteItem.Id}`).then(response => {
			if (response) refetch();
		});
	};
	const handleAdd = () => {
		history.push(`${history.location.pathname}/${type}`);
	};
	return (
		<>
			<List
				className={classes.list}
				component="nav"
				subheader={
					<ListSubheader className={classes.listHeader} component="div">
						{title}
					</ListSubheader>
				}
			>
				<Divider variant="middle" style={{ height: 2 }} />
				{data
					? data.map(item => {
							return (
								<React.Fragment key={item.id}>
									<ListItem onClick={() => handleClick(item)} button>
										<ListItemText primary={item[idField]} />
										<ListItemSecondaryAction>
											<IconButton
												onClick={() => handleDeleteClick(item)}
												edge="end"
												aria-label="delete"
											>
												<DeleteIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
									<Divider variant={'middle'} />
								</React.Fragment>
							);
					  })
					: null}
				{hasActions ? (
					<ListItem
						style={{ display: 'flex', justifyContent: 'center' }}
						onClick={() => handleAdd()}
						button
					>
						<ListItemIcon style={{ display: 'flex', justifyContent: 'center' }}>
							<AddIcon />
						</ListItemIcon>
					</ListItem>
				) : null}
			</List>
			<AlertDialog
				title={'Please Confirm'}
				open={deleteItem ? true : false}
				setOpen={setDeleteItem}
				confirmationText="delete"
				cancellationText="cancel"
				onConfirm={onDeleteConfirmed}
			>
				Are you sure you want to delete ({' '}
				{deleteItem ? deleteItem[idField] : type} ) ?
			</AlertDialog>
		</>
	);
}

export default FormTable;
