import React from 'react';
import { useFormikContext } from 'formik';
import { useToasts } from 'react-toast-notifications';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import { PeculiarFortifyCertificates } from '@peculiar/fortify-webcomponents-react';
import * as pvtsutils from 'pvtsutils';
import * as pkijs from 'pkijs';
import * as asn1js from 'asn1js';
import * as cadesjs from 'cadesjs';
import api from '../api';
import { useAppData } from '../contexts/appContext';

function FileInvoiceButton() {
	const { config } = useAppData();
	const { addToast } = useToasts();
	const { values, setFieldValue } = useFormikContext();
	const [state, setState] = React.useState({
		dialogShow: false,
		serializedInvoice: null
	});
	const handleClose = () => {
		setState({ ...state, dialogShow: false });
	};
	const onFileClick = () => {
		if (config.features.etaReceipt && values.partyType === 'client') {
			return api.post(`/api/filereceipt/${values.id}`).then(res => {
				const { data } = res;
				addToast('e-Receipt Filed', {
					appearance: 'success',
					autoDismiss: true
				});
				setFieldValue('etaReceiptId', data.etaReceiptId);
				setFieldValue('etaReceiptStatus', data.etaReceiptStatus);
			});
		}
		if (config.etaConfig.serverSigning)
			api.post(`/api/fileinvoice/${values.id}`).then(res => {
				const { data } = res;
				addToast('E-Invoice Filed', {
					appearance: 'success',
					autoDismiss: true
				});
				setFieldValue('etaInvoiceId', data.etaInvoiceId);
				setFieldValue('invoiceStatus', data.invoiceStatus);
			});
		else
			api.get(`/api/gov-invoice/${values.id}`).then(res => {
				const { data } = res;
				const { serializedInvoice } = data;
				setState({ ...state, dialogShow: true, serializedInvoice });
			});
	};
	const onCancelClick = () => {
		api.post(`/api/cancelinvoice/${values.id}`).then(res => {
			const { data } = res;
			addToast('Canceled E-Invoice', {
				appearance: 'success',
				autoDismiss: true
			});
			setFieldValue('etaInvoiceId', data.etaInvoiceId);
			setFieldValue('invoiceStatus', data.invoiceStatus);
		});
	};

	const onReturnClick = () => {
		api.post(`/api/filereturnreceipt/${values.id}`).then(res => {
			const { data } = res;
			addToast('Filed Return e-Receipt', {
				appearance: 'success',
				autoDismiss: true
			});
			setFieldValue('etaReturnReceiptId', data.etaReturnReceiptId);
			setFieldValue('etaReturnReceiptStatus', data.etaReturnReceiptStatus);
		});
	};

	async function continueHandler(event) {
		let singedInvoice;
		try {
			const invoiceData = pvtsutils.Convert.FromString(state.serializedInvoice);
			const provider = await event.detail.socketProvider.getCrypto(
				event.detail.providerId
			);
			let hashAlg = 'SHA-256';

			pkijs.setEngine(
				'newEngine',
				provider,
				new pkijs.CryptoEngine({
					name: '',
					crypto: provider,
					subtle: provider.subtle
				})
			);
			const crypto = pkijs.getCrypto();
			const cert = await provider.certStorage.getItem(
				event.detail.certificateId
			);
			const privateKey = await provider.keyStorage.getItem(
				event.detail.privateKeyId
			);
			const certRawData = await provider.certStorage.exportCert('raw', cert);

			const pkiCert = new pkijs.Certificate({
				schema: asn1js.fromBER(certRawData).result
			});
			const eSSCertIDv2 = new cadesjs.ESSCertIDv2();
			await eSSCertIDv2.fillValues({
				hashAlgorith: hashAlg,
				certificate: pkiCert
			});
			const signingCertificateV2 = new cadesjs.SigningCertificateV2({
				certs: [eSSCertIDv2]
			});

			const hashValue = await crypto.digest({ name: hashAlg }, invoiceData);

			const signedAttr = [];

			signedAttr.push(
				new pkijs.Attribute({
					type: '1.2.840.113549.1.9.3',
					values: [
						new asn1js.ObjectIdentifier({ value: '1.2.840.113549.1.7.5' })
					]
				})
			); // contentType

			signedAttr.push(
				new pkijs.Attribute({
					type: '1.2.840.113549.1.9.4',
					values: [new asn1js.OctetString({ valueHex: hashValue })]
				})
			); // messageDigest

			signedAttr.push(
				new pkijs.Attribute({
					type: '1.2.840.113549.1.9.5',
					values: [new asn1js.UTCTime({ valueDate: new Date() })]
				})
			); // signingTime
			signedAttr.push(
				new pkijs.Attribute({
					type: '1.2.840.113549.1.9.16.2.47',
					values: [signingCertificateV2.toSchema()]
				})
			);

			const signedData = new pkijs.SignedData({
				version: 3,
				encapContentInfo: new pkijs.EncapsulatedContentInfo({
					eContentType: '1.2.840.113549.1.7.5' // "data" content type,
				}),
				signerInfos: [
					new pkijs.SignerInfo({
						version: 1,
						sid: new pkijs.IssuerAndSerialNumber({
							issuer: pkiCert.issuer,
							serialNumber: pkiCert.serialNumber
						})
					})
				],
				certificates: [pkiCert]
			});
			signedData.signerInfos[0].signedAttrs = new pkijs.SignedAndUnsignedAttributes(
				{
					type: 0,
					attributes: signedAttr
				}
			);

			await signedData.sign(privateKey, 0, hashAlg);

			const cms = new pkijs.ContentInfo({
				contentType: '1.2.840.113549.1.7.2',
				content: signedData.toSchema(true)
			});
			const result = cms.toSchema().toBER(false);
			singedInvoice = pvtsutils.Convert.ToBase64(result);
		} catch (err) {
			handleClose();
			addToast('Failed to sign invoice', {
				appearance: 'error',
				autoDismiss: true
			});
		}
		if (singedInvoice != null) {
			handleClose();
			api
				.post(`/api/fileinvoice/${values.id}`, {
					signature: singedInvoice,
					govInvoice: state.invoice
				})
				.then(res => {
					const { data } = res;
					setFieldValue('etaInvoiceId', data.etaInvoiceId);
					setFieldValue('invoiceStatus', data.invoiceStatus);
					addToast('E-Invoice Filed', {
						appearance: 'success',
						autoDismiss: true
					});
				});
		}
	}
	if (values.etaInvoiceId) {
		return (
			<Button onClick={onCancelClick} variant="contained">
				Cancel E-Invoice
			</Button>
		);
	}
	if (values.etaReceiptId) {
		return (
			<Button onClick={onReturnClick} variant="contained">
				File Return e-Receipt
			</Button>
		);
	}
	if (values.etaReceiptId && values.etaReturnReceiptId) return <></>;
	return (
		<>
			<Button onClick={onFileClick} variant="contained" color="primary">
				{config.features.etaReceipt && values.partyType === 'client'
					? 'File e-Receipt'
					: 'File E-Invoice'}
			</Button>
			<Dialog onClose={handleClose} open={state.dialogShow} fullWidth>
				<DialogContent>
					<PeculiarFortifyCertificates
						filters={{
							onlyWithPrivateKey: true,
							onlySmartcards: true,
							expired: true
						}}
						onSelectionSuccess={continueHandler}
						onSelectionCancel={handleClose}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
}

export default FileInvoiceButton;
