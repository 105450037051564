import React from 'react';
import { GenericModelTable } from '../components';

const columns = [
	{
		name: 'name',
		label: 'Name',
		options: {
			filter: true,
			sort: true
		}
	},
	{
		name: 'phoneNumber',
		label: 'Phone Number',
		options: {
			filter: false,
			searchable: false,
			sort: false
		}
	},
	{
		name: 'email',
		label: 'Email',
		options: {
			filter: true,
			sort: false
		}
	}
];
const modelName = 'vendor';

function Vendors() {
	const tableOptions = {
		modelName,
		modelNamePlural: 'Vendors',
		columns: columns
	};
	return <GenericModelTable {...tableOptions} />;
}

export default Vendors;
