import React from 'react';
import AccountsTable from '../components/AccountsTable';
import Divider from '@material-ui/core/Divider';

const modelName = 'store';

export default {
	model: modelName,
	elements: [
		{
			field: 'number',
			readOnly: true,
			display: ({ values }) => {
				return values.active;
			}
		},
		{
			field: 'etaBranchId'
		},
		{
			field: 'name'
		},
		{
			field: 'type'
		},
		{
			field: 'phoneNumber'
		},
		{
			field: 'streetNumber'
		},
		{
			field: 'street'
		},
		{
			field: 'city'
		},
		{
			field: 'governorate'
		},
		{
			field: 'country'
		},
		{
			component: function divider() {
				return <Divider></Divider>;
			},
			display: ({ values }) => {
				return values.active;
			}
		},
		{
			field: 'posSerial',
			readOnly: true,
			display: ({ values }) => {
				return values.active;
			}
		},
		{
			field: 'posOsVersion',
			readOnly: true,
			display: ({ values }) => {
				return values.active;
			}
		},
		{
			field: 'posClientId',
			readOnly: true,
			display: ({ values }) => {
				return values.active;
			}
		},
		{
			component: function divider() {
				return <Divider></Divider>;
			},
			display: ({ values }) => {
				return values.active;
			}
		},
		{
			component: function accounts({ record, model }) {
				return <AccountsTable record={record} parentType={model} />;
			}
		}
	]
};
