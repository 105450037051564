import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { defineAbility, subject } from '@casl/ability';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { useToasts } from 'react-toast-notifications';
import { getRoutePrefix } from '../routes';
import { useAppData } from '../contexts/appContext';
import { useUserContext } from '../contexts/userContext';
import { useFetch } from '../api';
import computeFunctions from '../../shared/compute-functions';
import fieldTypes from '../field-types';
import Validation from '../../shared/services/Validation';
import RulesService from '../../shared/services/Rules';
import Form from './Form.js';
import { PageNotFound } from '../pages';

const validation = new Validation({ fieldTypes });

function getValidationSchema(modelFields, layoutElements, models) {
	const formFields = {};
	layoutElements.forEach(element => {
		if (element.field) formFields[element.field] = modelFields[element.field];
	});
	return validation.buildSchemaFromFields({ fields: formFields, models });
}

const isViewMode = props => {
	return props.recordId && props.mode === 'view';
};

function ModelForm(props) {
	const { formName, defaultValues = {}, initialMode, ...formProps } = props;
	const { user } = useUserContext();
	const [mode, setMode] = useState(initialMode);
	const appData = useAppData();
	const layouts = appData.layouts;
	const { addToast } = useToasts();
	const history = useHistory();
	const historyState = history.location.state;
	const { models } = appData;
	const { model: modelName, elements } = layouts[formName];
	const {
		fields,
		acl,
		rules: modelRules,
		pluralName: pluralModelName = ''
	} = models[modelName];
	const rulesService = new RulesService({
		rules: modelRules || {},
		config: appData.config
	});
	const [{ data, error, loading }] = useFetch({
		url: formProps.parentType
			? `/api/${modelName}/${formProps.recordId}?parentType=${formProps.parentType}`
			: `/api/${modelName}/${formProps.recordId}`,
		manual: formProps.recordId ? false : true
	});

	const [initialValues] = useMemo(() => {
		const fieldsValue = {};
		const recordData = data || {};
		for (const element of elements) {
			if (element.field) {
				if (element.field === formProps.parentIdName)
					fieldsValue[element.field] = formProps.parentId;
				else
					fieldsValue[element.field] =
						recordData[element.field] || defaultValues[element.field];
			}
		}
		return [{ ...defaultValues, ...recordData, ...fieldsValue }];
	}, [JSON.stringify(defaultValues), data]);

	const validationSchema = getValidationSchema(fields, elements, models);
	const formFields = elements.map(({ field, ...elementDef }) => {
		const compute = isViewMode(formProps)
			? undefined
			: computeFunctions[modelName]?.[field];
		return {
			name: field,
			compute,
			...fields[field],
			...elementDef
		};
	});
	const routePrefix = useMemo(
		() =>
			getRoutePrefix(formProps.parentType ? formProps.parentType : modelName),
		[modelName, formProps.parentType]
	);
	const { ability } = acl ? acl(user, defineAbility) : {};
	function aclCheck(values) {
		const modelNameCap = _.capitalize(modelName);
		const actions = {
			canCreate: true,
			canUpdate: true,
			canDelete: true
		};
		if (!ability) return actions;
		actions.canCreate = ability.can('create', modelNameCap);
		actions.canUpdate = ability.can('update', subject(modelNameCap, values));
		actions.canDelete = ability.can('delete', subject(modelNameCap, values));
		return actions;
	}

	const additionalProps = {
		fields,
		modelName,
		pluralModelName,
		routePrefix,
		rulesService,
		initialValues,
		validationSchema,
		formFields,
		models,
		modelValues: data,
		addToast,
		history,
		historyState,
		mode,
		setMode,
		aclCheck
	};
	Object.keys(additionalProps).forEach(propKey => {
		formProps[propKey] = additionalProps[propKey];
	});

	if (!data && !error && formProps.recordId) return <CircularProgress />;
	if (error && !loading && error.response?.status === 404)
		return <PageNotFound />;
	return <Form {...formProps} />;
}

export default ModelForm;
