module.exports = {
	rules: {
		roleIsSales(record) {
			return record.role === 'sales';
		}
	},
	acl(user, defineAbility) {
		const ability = defineAbility((can, cannot) => {
			can('read', 'User');
			if (user.role === 'admin') {
				can(['create', 'update'], 'User');
			} else {
				cannot(['create', 'update'], 'User');
			}
			cannot('delete', 'User');
		});

		return { ability };
	}
};
