module.exports = {
	rules: {
		idIsEmpty(record) {
			return record.id == null;
		}
	},
	acl(user, defineAbility) {
		const ability = defineAbility((can, cannot) => {
			if (user.role === 'admin') {
				can('manage', 'Account');
			} else {
				cannot('manage', 'Account');
			}
		});

		return { ability };
	}
};
