module.exports = {
	number: {
		dependentFields: [],
		formulate: ({ id, number }) => {
			return id ? number : Math.floor(Math.random() * 10000);
		}
	},
	caratRange: {
		dependentFields: ['minCarat', 'maxCarat'],
		formulate: ({ minCarat, maxCarat }) => {
			return `${minCarat} - ${maxCarat}`;
		}
	}
};
