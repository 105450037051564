import React from 'react';
import api from '../api';
import _ from 'lodash';
import { saveAs } from 'file-saver';
import { GenericModelTable } from '../components';

const columns = [
	{
		name: 'name',
		label: 'Name',
		options: {
			filter: true,
			sort: true
		}
	},
	{
		name: 'startDate',
		label: 'Start Date',
		options: {
			filter: true,
			sort: false
		}
	},
	{
		name: 'endDate',
		label: 'End Date',
		options: {
			filter: false,
			searchable: false,
			sort: false
		}
	},
	{
		name: 'invoiceCount',
		label: 'Number of Invoices',
		options: {
			filter: false,
			searchable: false,
			sort: false
		}
	},
	{
		name: 'expiresOn',
		label: 'Expiration Date',
		options: {
			filter: false,
			searchable: false,
			sort: false
		}
	},
	{
		name: 'createdDate',
		label: 'Created Date',
		options: {
			filter: false,
			searchable: false,
			sort: false
		}
	}
];
const modelName = 'invoicePackage';

function getFileName(res) {
	let filename = '';
	const disposition = res.headers['content-disposition'];
	if (disposition && disposition.indexOf('attachment') !== -1) {
		var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
		var matches = filenameRegex.exec(disposition);
		if (matches != null && matches[1]) {
			filename = matches[1].replace(/['"]/g, '');
		}
	}
	return filename;
}

function InvoicePackages() {
	const tableOptions = {
		modelName,
		modelNamePlural: 'Invoice Packages',
		columns,
		onRowClick: _.throttle(itemId => {
			api({
				method: 'GET',
				url: `/api/invoice-package/download/${itemId}`,
				responseType: 'arraybuffer'
			}).then(res => {
				saveAs(new Blob([res.data]), getFileName(res), {
					type: res.headers['content-type']
				});
			});
		}, 1000)
	};
	return <GenericModelTable {...tableOptions} />;
}

export default InvoicePackages;
