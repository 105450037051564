import React from 'react';
import imageFieldType from '../../shared/field-types/image';
export default {
	...imageFieldType,
	format() {
		return '';
	},
	component: function Image() {
		return <></>;
	}
};
