import React from 'react';
import { CustomDateTimePicker } from '../components';
import moment from 'moment';
import sharedDateTimeFieldType from '../../shared/field-types/datetime';

export default {
	...sharedDateTimeFieldType,
	component: function dateTime(props) {
		return <CustomDateTimePicker {...props} />;
	},
	format: ({ field, record }) => {
		return moment(record[field]).format('yyyy-MM-DD hh:mm');
	},
	convertToForm(value) {
		return value || null;
	}
};
