import React from 'react';
import { TextBox } from '../components';
import textBoxFieldType from '../../shared/field-types/textBox';

export default {
	...textBoxFieldType,
	component: function textbox(props) {
		return <TextBox {...props} />;
	},
	format: ({ field, record }) => {
		return record[field];
	},
	queryParameter: ({ name }) => name
};
