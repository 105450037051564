import React from 'react';
import { get } from 'lodash';
import { TextBox } from '../components';
import decimalFieldType from '../../shared/field-types/decimal';

export default {
	...decimalFieldType,
	component: function decimal(props) {
		return <TextBox type="number" {...props} />;
	},
	format: ({ field, record }) => {
		return get(record, field, '');
	}
};
