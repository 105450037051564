import boolean from './boolean';
import childMultiple from './child-multiple';
import currency from './currency';
import date from './date';
import datetime from './dateTime';
import decimal from './decimal';
import dropdown from './dropdown';
import email from './email';
import file from './file';
import id from './id';
import ids from './ids';
import invoice from './invoice';
import image from './image';
import item from './item';
import itemModel from './itemModel';
import items from './items';
import number from './number';
import parcel from './parcel';
import parcels from './parcels';
import party from './party';
import secret from './secret';
import store from './store';
import stores from './stores';
import textarea from './textArea';
import textbox from './textBox';
import jsonarray from './jsonArray';
import partyaccount from './partyaccount';
import percentage from './percentage';

export default {
	boolean,
	[childMultiple.name]: childMultiple,
	currency,
	date,
	datetime,
	decimal,
	dropdown,
	email,
	file,
	id,
	ids,
	invoice,
	image,
	item,
	itemModel,
	items,
	number,
	parcel,
	parcels,
	party,
	secret,
	store,
	stores,
	textarea,
	textbox,
	jsonarray,
	partyaccount,
	percentage
};
