import React from 'react';
import ids from './ids';
import itemsFieldType from '../../shared/field-types/items';

const configs = {
	model: 'item',
	idsMapper: 'items'
};

export default {
	...itemsFieldType,
	component: function items(props) {
		return <ids.component {...props} {...configs} />;
	},
	format: props => {
		return ids.format({ ...props, ...configs });
	}
};
