import Big from 'big.js';
import React, { useLayoutEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import InvoiceTableRow from './InvoiceTableRow';

const useStyles = makeStyles(theme => ({
	addRowLink: {
		margin: theme.spacing(2)
	},
	totalTable: {
		width: '70%',
		'& td': {
			'border-bottom-width': '0px'
		}
	}
}));

function InvoiceTable({ arrayHelpers, ...props }) {
	const { invoiceLineOptions, columns, readOnly } = props;
	const { invoiceLinesField } = invoiceLineOptions;
	const invoiceLines = arrayHelpers.form.values[invoiceLinesField];
	const classes = useStyles();
	const totalValueAddedTax = invoiceLines.reduce((a, b) => {
		if (b.valueAddedTax > 0 && b.amount > 0) {
			return Big(b.valueAddedTax)
				.div(100)
				.mul(b.amount)
				.plus(a)
				.toNumber();
		}
		return a;
	}, 0);
	const totalWithholdingTax = invoiceLines.reduce((a, b) => {
		if (b.withholdingTax > 0 && b.amount > 0) {
			return Big(b.withholdingTax)
				.div(100)
				.mul(b.amount)
				.plus(a)
				.toNumber();
		}
		return a;
	}, 0);
	useLayoutEffect(() => {
		const totalPrice = invoiceLines.reduce((a, b) => {
			if (b.amount > 0) {
				return Big(b.amount)
					.plus(a)
					.toNumber();
			}
			return a;
		}, 0);
		arrayHelpers.form.setFieldValue(
			'totalPrice',
			Big(totalPrice)
				.plus(totalValueAddedTax)
				.minus(totalWithholdingTax)
				.toNumber()
		);
	}, [JSON.stringify(invoiceLines)]);
	return (
		<>
			<Table aria-label="invoice table">
				<TableHead>
					<TableRow>
						{columns.map((column, index) => (
							<TableCell key={index}>{column.label}</TableCell>
						))}

						{/* column for the delete icon */}
						{!readOnly ? <TableCell /> : null}
					</TableRow>
				</TableHead>
				<TableBody>
					{invoiceLines.map((invoiceLine, lineIndex) => (
						<InvoiceTableRow
							key={lineIndex}
							invoiceLine={invoiceLine}
							record={arrayHelpers.form.values}
							lineIndex={lineIndex}
							deleteCell={
								!readOnly ? (
									<TableCell>
										<IconButton
											color="secondary"
											onClick={() => {
												arrayHelpers.remove(lineIndex);
											}}
										>
											<DeleteIcon fontSize="small" />
										</IconButton>
									</TableCell>
								) : null
							}
							{...props}
						/>
					))}
				</TableBody>
			</Table>
			<Grid container justify="space-between">
				<Grid item xs={3}>
					<div className={classes.addRowLink}>
						{!readOnly ? (
							<Button
								color="primary"
								onClick={() => {
									arrayHelpers.push({
										saleCategory: 'product',
										itemId: '',
										price: 0,
										quantity: 0,
										discount: 0,
										valueAddedTax: 0,
										withholdingTax: 0,
										amount: 0,
										total: 0
									});
								}}
								startIcon={<AddCircleOutlineIcon />}
							>
								Add Line
							</Button>
						) : null}
					</div>
				</Grid>

				<Grid item xs={2}>
					<Table className={classes.totalTable}>
						<TableHead></TableHead>
						<TableBody>
							{totalValueAddedTax > 0 ? (
								<TableRow>
									<TableCell scope="row">
										Value added tax (ضريبه القيمه المضافه):
									</TableCell>
									<TableCell scope="row">
										<Typography>{totalValueAddedTax}</Typography>
									</TableCell>
								</TableRow>
							) : null}
							{totalWithholdingTax > 0 ? (
								<TableRow>
									<TableCell scope="row">
										Withholding tax (الخصم تحت حساب الضريبه):
									</TableCell>
									<TableCell scope="row">
										<Typography>{totalWithholdingTax}</Typography>
									</TableCell>
								</TableRow>
							) : null}

							{invoiceLines.length > 0 ? (
								<TableRow>
									<TableCell scope="row">Total:</TableCell>
									<TableCell scope="row">
										<Typography>
											{arrayHelpers.form.values['totalPrice']}
										</Typography>
									</TableCell>
								</TableRow>
							) : null}
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		</>
	);
}

export default InvoiceTable;
