import _ from 'lodash';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MUIDataTable from 'mui-datatables';

import api from '../api';
import { useAppData } from '../contexts/appContext';
import fieldTypes from '../field-types';

const tableIcons = {
	Add: forwardRef((props, ref) => (
		<AddBox displayName={AddBox} {...props} ref={ref} />
	)),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => (
		<Edit {...props} displayName="Edit" ref={ref} />
	)),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
tableIcons.Add.displayName = 'Add';
tableIcons.Check.displayName = 'Check';
tableIcons.Clear.displayName = 'Clear';
tableIcons.Delete.displayName = 'Delete';
tableIcons.DetailPanel.displayName = 'DetailPanel';
tableIcons.Edit.displayName = 'Edit';
tableIcons.Export.displayName = 'Export';
tableIcons.Filter.displayName = 'Filter';
tableIcons.FirstPage.displayName = 'FirstPage';
tableIcons.LastPage.displayName = 'LastPage';
tableIcons.NextPage.displayName = 'NextPage';
tableIcons.PreviousPage.displayName = 'PreviousPage';
tableIcons.ResetSearch.displayName = 'ResetSearch';
tableIcons.Search.displayName = 'Search';
tableIcons.SortArrow.displayName = 'SortArrow';
tableIcons.ThirdStateCheck.displayName = 'ThirdStateCheck';
tableIcons.ViewColumn.displayName = 'ViewColumn';

function setCustomFilters(customFilters, url) {
	if (typeof customFilters === 'object') {
		Object.keys(customFilters).forEach(key => {
			url.searchParams.set(key, customFilters[key]);
		});
	}
}

function Table({
	columns,
	model,
	title,
	isChild = false,
	parentId,
	parentType,
	parentIdName = 'parentId',
	onRowClick,
	customFilters,
	reloadProp,
	tableOptions
}) {
	const muiTableColumns = columns.map(column => {
		if (column.cellTemplate) {
			columns.options = column.options || {};
			columns.options.customBodyRenderLite = dataIndex => {
				const value = _.get(state.data, [dataIndex, column.name]);
				return column.cellTemplate({ value });
			};
		}
		return column;
	});

	const [state, setState] = useState({
		page: 0,
		columns: muiTableColumns,
		isLoading: !isChild || (isChild && parentId),
		count: 0,
		rowsPerPage: 10,
		data: [],
		search: true,
		filter: true,
		sort: true,
		...tableOptions
	});

	const appData = useAppData();
	const modelFields = appData ? appData.models[model].fields : null;
	useEffect(() => {
		if (!isChild || (isChild && parentId)) {
			const url = new URL(`api/search/${model}`, api.defaults.baseURL);
			url.searchParams.set('rangeStart', '0');
			url.searchParams.set('rangeEnd', state.rowsPerPage - 1);
			if (isChild) url.searchParams.set(parentIdName, parentId);
			if (parentType) url.searchParams.set('parentType', parentType);
			setCustomFilters(customFilters, url);
			api
				.request({
					url: url.pathname + url.search
				})
				.then(res => {
					setState({
						...state,
						data: formatData(res.data.results),
						count: res.data.total,
						isLoading: false
					});
				});
		}
	}, [isChild, parentType, model, parentId, customFilters, reloadProp]);

	const formatData = data => {
		data.forEach(ent => {
			columns.forEach(column => {
				const fieldType =
					modelFields &&
					modelFields[column.name] &&
					fieldTypes[modelFields[column.name].type]
						? modelFields[column.name].type
						: 'textbox';
				ent[column.name] = fieldTypes[fieldType].format({
					field: column.name,
					record: ent,
					models: appData.models,
					currencies: appData.currencies
				});
			});
		});
		return data;
	};

	const searching = useRef();

	const nextUrl = tableState => {
		const newUrl = new URL(`api/search/${model}`, api.defaults.baseURL);
		if (isChild) {
			newUrl.searchParams.set(parentIdName, parentId);
			if (parentType) newUrl.searchParams.set('parentType', parentType);
		}
		newUrl.searchParams.set(
			'rangeStart',
			tableState.page * tableState.rowsPerPage
		);
		newUrl.searchParams.set(
			'rangeEnd',
			(tableState.page + 1) * tableState.rowsPerPage - 1
		);
		if (tableState.sortOrder.direction && tableState.sortOrder.name) {
			tableState.sortOrder.direction === 'asc'
				? newUrl.searchParams.set('orderBy', tableState.sortOrder.name)
				: newUrl.searchParams.set('orderByDesc', tableState.sortOrder.name);
		}
		//SEARCH
		if (tableState.searchText) {
			var searchQuery = '';
			tableState.columns.forEach(column => {
				if (isSearchable(column))
					searchQuery += `${fieldTypes[
						modelFields[column.name].type
					].queryParameter({ name: column.name, models: appData.models }) ||
						fieldTypes['textbox'].queryParameter(column.name)}|`;
			});
			searchQuery = searchQuery.substr(0, searchQuery.length - 1);
			newUrl.searchParams.set(
				`${searchQuery}:likeLower`,
				`%${tableState.searchText}%`
			);
		}
		//FILTER
		for (var i = 0; i < tableState.filterList.length; i++) {
			if (tableState.filterList[i][0])
				newUrl.searchParams.set(
					`${fieldTypes[
						modelFields[tableState.columns[i].name].type
					].queryParameter({
						name: tableState.columns[i].name,
						models: appData.models
					}) ||
						fieldTypes['textbox'].queryParameter(tableState.columns[i].name)}`,
					tableState.filterList[i][0]
				);
		}

		setCustomFilters(customFilters, newUrl);

		return newUrl.pathname + newUrl.search;
	};
	//searchable logic
	const isSearchable = column => {
		return modelFields && modelFields[column.name]
			? modelFields[column.name].type === 'textbox' ||
					modelFields[column.name].type === 'store'
			: false;
	};
	//returns columns with filterList added to each respective column
	const addFilterList = (columns, filterList) => {
		for (var i = 0; i < columns.length; i++) {
			columns[i].options.filterList = filterList[i];
		}
		return columns;
	};
	//timeout for backend requests
	const handleSearch = tableState => {
		if (searching.current) {
			clearTimeout(searching.current);
		}
		if (!tableState.searchText) handleTableChange(tableState, true);
		else
			searching.current = setTimeout(() => {
				handleTableChange({
					...tableState,
					newPage: true,
					ignoreLoading: true
				});
			}, 500);
	};
	const handleTableChange = tableState => {
		if (!tableState.ignoreLoading) setState({ ...state, isLoading: true });
		api
			.request({
				url: nextUrl(
					tableState.newPage ? { ...tableState, page: 0 } : tableState
				)
			})
			.then(res => {
				setState({
					...state,
					page: tableState.newPage ? 0 : tableState.page,
					data: formatData(res.data.results),
					count: res.data.total,
					sortOrder: tableState.sortOrder,
					rowsPerPage: tableState.rowsPerPage,
					columns: addFilterList(columns, tableState.filterList),
					isLoading: false
				});
			});
	};
	const options = {
		filter: state.filter,
		pagination: state.pagination,
		filterType: 'dropdown',
		serverSide: true,
		selectableRows: 'none',
		download: false,
		print: false,
		onTableChange: (action, tableState) => {
			switch (action) {
				case 'changePage':
				case 'changeRowsPerPage':
					handleTableChange(tableState);
					break;
				case 'sort':
				case 'filterChange':
				case 'resetFilters':
					handleTableChange({ ...tableState, newPage: true });
					break;
				case 'search':
					handleSearch(tableState);
					break;
				default:
					setState(state);
			}
		},
		sort: state.sort,
		count: state.count,
		page: state.page,
		rowsPerPage: state.rowsPerPage,
		rowsPerPageOptions: [0, 5, 10, 15, 100],
		sortOrder: state.sortOrder,
		viewColumns: state.viewColumns,
		search: state.search
			? state.columns.some(column => isSearchable(column))
			: false,
		onRowClick:
			onRowClick &&
			((rowData, rowMeta) => {
				onRowClick(state.data[rowMeta.rowIndex].id);
			}),
		customToolbar: state.customToolbar
	};

	return (
		<MUIDataTable
			columns={state.columns}
			data={state.data}
			title={
				<Typography variant="h6">
					{state.isLoading ? <CircularProgress /> : title}
				</Typography>
			}
			options={options}
		/>
	);
}

export default Table;
