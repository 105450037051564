import React from 'react';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { ModelForm } from '../components';
import { useParams } from 'react-router-dom';
import PrintIcon from '@material-ui/icons/Print';

export default function Invoice({ formMode }) {
	const { id } = useParams();
	let defaultValues = {};
	if (!id) {
		defaultValues = {
			invoiceDate: moment().toISOString(),
			partyType: 'client'
		};
	}

	return (
		<Grid container>
			<ModelForm
				recordId={id}
				formName={'invoiceLayout'}
				defaultValues={defaultValues}
				initialMode={formMode}
				isDraftable={true}
				customActionButtons={[
					{
						text: 'Print',
						startIcon: <PrintIcon />,
						color: 'primary',
						display(form) {
							return form.values.id && form.mode === 'view';
						},
						href: `/invoice/download/${id}`,
						target: '_blank'
					}
				]}
			/>
		</Grid>
	);
}
