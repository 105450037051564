import React from 'react';
import { get } from 'lodash';
import { TextBox } from '../components';
import percentageFieldType from '../../shared/field-types/percentage';

export default {
	...percentageFieldType,
	component: function percentage(props) {
		return <TextBox type="number" {...props} />;
	},
	format: ({ field, record }) => {
		return get(record, field, '');
	}
};
