import React from 'react';
import { GenericModelTable } from '../components';
const columns = [
	{
		name: 'name',
		label: 'Name',
		options: {
			filter: true,
			sort: true
		}
	},
	{
		name: 'symbol',
		label: 'Symbol',
		options: {
			sort: true,
			filter: false,
			searchable: false
		}
	},
	{
		name: 'default',
		label: 'Default',
		options: {
			sort: false,
			filter: false,
			searchable: false
		}
	}
];
const modelName = 'currency';

function Currencies() {
	const tableOptions = {
		modelName,
		modelNamePlural: 'Currencies',
		columns
	};
	return <GenericModelTable {...tableOptions} />;
}

export default Currencies;
