module.exports = {
	rules: {
		isSystemPartyTrue(record) {
			return record.systemParty === true;
		},
		isSystemPartyTrueOrPartyTypeVendor(record) {
			return record.systemParty === true || record.partyType === 'vendor';
		},
		isSystemPartyNotTrue(record) {
			return record.systemParty !== true;
		},
		isSystemPartyNotTrueAndTotalPriceIsMoreThanOrEqualTo50k(record) {
			return (
				record.partyType === 'client' &&
				record.systemParty !== true &&
				parseFloat(record.totalPrice) >= 50000
			);
		},
		isClientAndForReceipt(record, config) {
			return config.features.etaReceipt && record.partyType === 'client';
		},
		isVendorOrForInvoiceAndClient(record, config) {
			if (!config.features.etaReceipt) return true;
			return record.partyType === 'vendor';
		},
		isTotalPriceAboveThresholdForClient(record, config) {
			const {
				invoiceThresholdForClientId,
				receiptThresholdForClientId
			} = config.etaConfig.invoiceConfig;
			const threshold = config.features.etaReceipt
				? receiptThresholdForClientId
				: invoiceThresholdForClientId;
			return (
				record.partyType === 'client' &&
				parseFloat(record.totalPrice) >= threshold
			);
		}
	},
	acl(user, defineAbility) {
		let restrictedFields = [];
		const ability = defineAbility((can, cannot) => {
			if (user.role === 'admin') {
				can('manage', 'Invoice');
				can('manage-eta', 'Invoice');
			} else {
				can('manage', 'Invoice', { storeId: { $in: user.storeIds } });
				cannot('delete', 'Invoice');
				cannot('manage-eta', 'Invoice');
				restrictedFields = [
					'etaInvoiceId',
					'etaSubmissionId',
					'etaInvoiceStatus'
				];
			}
			cannot(['delete', 'update'], 'Invoice', {
				invoiceStatus: { $eq: 'filed' }
			});
			cannot(['delete', 'update'], 'Invoice', {
				receiptStatus: { $in: ['filed', 'returned'] }
			});
		});
		return { ability, restrictedFields };
	}
};
