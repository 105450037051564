import React from 'react';
import { Grid } from '@material-ui/core';
import { ModelForm } from '../components';
import { useParams } from 'react-router-dom';

export default function Item({ formMode }) {
	const { id } = useParams();
	return (
		<Grid container>
			<ModelForm
				recordId={id}
				defaultValues={{
					goldWeight: 0
				}}
				formName={'createItemForm'}
				initialMode={formMode}
			/>
		</Grid>
	);
}
