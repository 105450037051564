import React from 'react';
import TransactionsTable from '../components/TransactionsTable';

const modelName = 'account';

export default {
	model: modelName,
	elements: [
		{
			field: 'number'
		},
		{
			field: 'currencyId'
		},
		{
			field: 'balance'
		},
		{
			component: function transactions({ record, mode, model }) {
				return (
					<TransactionsTable
						parentId={record.id}
						parentType={model}
						parentMode={mode}
					/>
				);
			}
		}
	]
};
