const _ = require('lodash');
module.exports = {
	rules: {
		categoryContainsDiamondOrMountingsOrGold(record) {
			return _.includes(['diamond', 'gold', 'mountings'], record.category);
		},
		categoryIsDiamondOrCategoryIsGoldAndKaratIs18K(record) {
			const categoryIsDiamond = record.category === 'diamond';
			const categoryIsGold = record.category === 'gold';
			const goldKaratIs18K = record.goldKarat === '18k';
			return categoryIsDiamond || (categoryIsGold && goldKaratIs18K);
		}
	},
	acl(user, defineAbility) {
		const ability = defineAbility((can, cannot) => {
			if (user.role === 'admin') {
				can('manage', 'Item');
			} else {
				cannot('manage', 'Item');
				can('read', 'Item');
			}
		});

		return { ability };
	}
};
