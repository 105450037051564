const Big = require('big.js');

module.exports = {
	total: {
		dependentFields: ['valueAddedTax', 'withholdingTax', 'amount'],
		formulate: ({ valueAddedTax, withholdingTax, amount }) => {
			const valueAddedTaxRate = Big(valueAddedTax > 0 ? valueAddedTax : 0).div(
				100
			);
			const withholdingTaxRate = Big(
				withholdingTax > 0 ? withholdingTax : 0
			).div(100);
			const amountValue = Big(amount > 0 ? amount : 0);
			const valueAddedTaxAmount = amountValue.mul(valueAddedTaxRate);
			const withholdingTaxAmount = amountValue.mul(withholdingTaxRate);
			return amountValue
				.plus(valueAddedTaxAmount)
				.minus(withholdingTaxAmount)
				.toNumber();
		}
	}
};
