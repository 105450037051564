import React from 'react';
import { TextBox } from '../components';
import numberFieldType from '../../shared/field-types/number';

export default {
	...numberFieldType,
	component: function number(props) {
		return <TextBox type="number" {...props} />;
	},
	format: ({ field, record }) => {
		return record[field];
	},
	convertToDb(val) {
		const parsedInt = parseInt(val);

		return Number.isNaN(parsedInt) ? null : parsedInt;
	}
};
