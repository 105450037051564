import React from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik, useFormikContext } from 'formik';
import {
	Button,
	Divider,
	Grid,
	FormLabel,
	Typography,
	List,
	ListItem,
	ListItemText,
	ListItemIcon
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { useAppData } from '../contexts/appContext';
import { useUserContext } from '../contexts/userContext';
import { TextBox } from '../components';
import api from '../api';

const useStyles = makeStyles(theme => ({
	paper: {
		minWidth: '250px',
		maxWidth: '350px',
		width: '40%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: theme.spacing(3)
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
		width: '25px',
		height: '25px',
		border: `2px solid ${theme.palette.background.paper}`
	},
	form: {
		'& .MuiTextField-root': {
			marginTop: theme.spacing(2)
		},
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	button: {
		margin: theme.spacing(5, 0, 2)
	},
	largeLogo: {
		width: theme.spacing(10),
		height: theme.spacing(10)
	},
	fieldIcon: {
		marginRight: theme.spacing(1)
	},
	field: {
		marginLeft: theme.spacing(1),
		'& .MuiInputBase-root': {
			width: '220px'
		}
	},
	label: {
		marginTop: theme.spacing(3),
		textAlign: 'left',
		verticalAlign: 'middle',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'left'
		}
	},
	list: {
		marginTop: theme.spacing(2)
	},
	password: {
		margin: theme.spacing(1),
		marginLeft: theme.spacing(6)
	},
	passwordError: {
		color: '#ff0000'
	},
	passwordValid: {
		color: '#1bcc1c'
	}
}));

function PasswordForm() {
	const { values } = useFormikContext();
	const classes = useStyles();
	const appData = useAppData();
	const passwordConfig = appData.config.password;

	const passwordRequirements = React.useMemo(() => {
		const { required, allowedChars, minLength } = passwordConfig;
		const requirements = [];
		const { newPassword, confirmPassword } = values;
		const allowedCharsRegex = new RegExp(allowedChars.regex);
		const allowedCharsMessage = {
			message: allowedChars.message
		};
		allowedCharsMessage.valid = allowedCharsRegex.test(newPassword);
		requirements.push(allowedCharsMessage);
		const minLengthMessage = {
			message: `At least ${minLength} characters`
		};
		minLengthMessage.valid = newPassword.length >= minLength;
		requirements.push(minLengthMessage);
		required.forEach(requirement => {
			const regex = new RegExp(requirement.regex);
			requirements.push({
				message: requirement.message,
				valid: regex.test(newPassword)
			});
		});
		requirements.push({
			message: 'Passwords must match',
			valid: newPassword === confirmPassword && newPassword.length !== 0
		});
		return requirements;
	}, [
		values.newPassword,
		values.confirmPassword,
		JSON.stringify(passwordConfig)
	]);

	return (
		<Form className={classes.form}>
			<Grid container item xs={8} className={classes.field}>
				<Grid item xs={3} className={classes.label}>
					<FormLabel htmlFor="currentPassword">Current Password:</FormLabel>
				</Grid>
				<Grid item xs="auto">
					<Field
						name="currentPassword"
						type={'password'}
						component={TextBox}
						required={true}
					/>
				</Grid>
			</Grid>
			<Grid container item xs={8} className={classes.field}>
				<Grid item xs={3} className={classes.label}>
					<FormLabel htmlFor="newPassword">New Password:</FormLabel>
				</Grid>
				<Grid item xs="auto">
					<Field
						name="newPassword"
						type={'password'}
						component={TextBox}
						required={true}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				item
				xs={12}
				justify="center"
				className={classes.password}
			>
				<Grid item xs={12}>
					<List dense className={classes.list}>
						{passwordRequirements.map((requirement, i) => {
							const colorClass =
								requirement.valid === true
									? classes.passwordValid
									: classes.passwordError;
							const Icon = requirement.valid ? CheckIcon : CloseIcon;
							return (
								<ListItem key={`password-requiremnt-${i}`}>
									<ListItemIcon className={colorClass}>
										<Icon />
									</ListItemIcon>
									<ListItemText className={colorClass}>
										{' '}
										{requirement.message}
									</ListItemText>
								</ListItem>
							);
						})}
					</List>
				</Grid>
				;
			</Grid>
			<Grid container item xs={8} className={classes.field}>
				<Grid item xs={3} className={classes.label}>
					<FormLabel htmlFor="confirmPassword">Confirm Password:</FormLabel>
				</Grid>
				<Grid item xs="auto">
					<Field
						name="confirmPassword"
						type={'password'}
						component={TextBox}
						required={true}
					/>
				</Grid>
			</Grid>
			<Grid item xs={2}>
				<Button
					className={classes.button}
					variant="contained"
					color="primary"
					fullWidth
					type="submit"
				>
					Change Password
				</Button>
			</Grid>
		</Form>
	);
}

export default function ChangePassword() {
	const classes = useStyles();
	const appData = useAppData();
	const { user, setUserState } = useUserContext();
	const passwordConfig = appData.config.password;
	const { allowedChars, minLength, required } = passwordConfig;
	const onChangePassword = values => {
		return api.post(`/api/user/${user.id}/change-password`, values).then(() => {
			setUserState(null);
		});
	};
	let passwordValidationSchema = Yup.string();
	passwordValidationSchema = passwordValidationSchema.test({
		test() {
			const { parent } = this;
			const { newPassword, confirmPassword } = parent;
			const allowedCharsMet = new RegExp(allowedChars.regex).test(newPassword);
			const minLengthMet = newPassword && newPassword.length >= minLength;
			const requirementsMet = required.every(requirement =>
				new RegExp(requirement.regex).test(newPassword)
			);
			const passwordsAreIdentical = newPassword === confirmPassword;
			return (
				allowedCharsMet &&
				minLengthMet &&
				requirementsMet &&
				passwordsAreIdentical
			);
		},
		message: 'Please make sure all the conditions below are met'
	});
	return (
		<Grid container>
			<Grid container direction="row" alignItems="center">
				<Typography variant="h6">Change Password</Typography>
			</Grid>
			{user.isTempPassword ? (
				<Grid container direction="row" alignItems="center">
					<Alert severity="warning">
						You are currently using a temporary password, you will not be able
						to use the system until it is changed.
					</Alert>
				</Grid>
			) : null}
			<Divider />
			<Grid container className={classes.root}>
				<Grid item xs={12}>
					<Formik
						validationSchema={Yup.object({
							currentPassword: Yup.string().required('Required'),
							newPassword: passwordValidationSchema,
							confirmPassword: Yup.string().required('Required')
						})}
						initialValues={{
							currentPassword: '',
							newPassword: '',
							confirmPassword: ''
						}}
						onSubmit={onChangePassword}
						component={PasswordForm}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
}
