import React from 'react';
import id from './id';
import partyFieldType from '../../shared/field-types/party';

export default {
	...partyFieldType,
	component: function party({ ...props }) {
		const idType = props.form?.values?.partyType;
		if (!idType) return null;
		return <id.component {...props} idType={idType} />;
	},
	format: props => {
		const idType = props.record?.partyType.toLowerCase();
		return id.format({ ...props, idType });
	},
	queryParameter: props => {
		const idType = props.record?.partyType.toLowerCase();
		return id.queryParameter({ ...props, idType });
	},
	readOnlyComponent: function party(props) {
		const idType = props.form?.values?.partyType;
		if (!idType) return null;
		return <id.readOnlyComponent {...props} idType={idType} />;
	}
};
