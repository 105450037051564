import React from 'react';
import { fieldToKeyboardDatePicker } from 'formik-material-ui-pickers';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

function CustomDatePicker(props) {
	const { form, field, label } = props;
	const datePickerProps = fieldToKeyboardDatePicker({ form, field, label });
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardDatePicker
				{...datePickerProps}
				variant="inline"
				format="dd-MM-yyyy"
			/>
		</MuiPickersUtilsProvider>
	);
}

export default CustomDatePicker;
