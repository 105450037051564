import React from 'react';
import { GenericModelTable } from '../components';

const columns = [
	{
		name: 'username',
		label: 'Username',
		options: {
			filter: true,
			sort: true
		}
	},
	{
		name: 'firstName',
		label: 'First Name',
		options: {
			filter: true,
			sort: true
		}
	},
	{
		name: 'lastName',
		label: 'Last Name',
		options: {
			filter: true,
			sort: true
		}
	},
	{
		name: 'role',
		label: 'Role',
		options: {
			filter: true,
			sort: true
		}
	}
];
const modelName = 'user';

function Users() {
	const tableOptions = {
		modelName,
		modelNamePlural: 'Users',
		columns: columns
	};
	return <GenericModelTable {...tableOptions} />;
}

export default Users;
