import React from 'react';
import _ from 'lodash';
import { TextField, Typography } from '@material-ui/core';
import { fieldToAutocomplete } from 'formik-material-ui-lab';
import { useFetch } from '../api';
import { useAppData } from '../contexts/appContext';
import dropdownFieldType from '../../shared/field-types/dropdown';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fieldToTextField } from 'formik-material-ui';

const modelName = 'dropdown';
const getDropdownUrl = dropdownName => {
	return `/api/${modelName}?name:eq=${dropdownName}`;
};

export default {
	...dropdownFieldType,
	component: function dropdown({ dropdownName, ...props }) {
		const [{ data }] = useFetch({ url: getDropdownUrl(dropdownName) });
		const appData = useAppData();
		const idField = appData?.models[modelName].identifierFields[0];
		const choices = data?.[0].dropdownItem || [];
		const sortedChoices = _.sortBy(choices, 'name');
		const { form, field } = props;
		const autoCompleteProps = fieldToAutocomplete({ form, field });
		const textFieldProps = fieldToTextField({ form, field });
		const value = choices.find(choice => choice.value === field.value) || null;
		return (
			<Autocomplete
				{...autoCompleteProps}
				value={value}
				options={sortedChoices}
				getOptionLabel={option => option[idField] || ''}
				onChange={(_, item) => {
					form.setFieldValue(field.name, item?.value || null);
				}}
				getOptionSelected={(item, current) => {
					return item && current ? item.value === current.value : false;
				}}
				renderInput={params => (
					<TextField
						{...params}
						error={textFieldProps.error}
						helperText={textFieldProps.helperText}
					/>
				)}
			/>
		);
	},
	readOnlyComponent: function Dropdown({ dropdownName, field }) {
		const { models } = useAppData();
		const [{ data }] = useFetch({ url: getDropdownUrl(dropdownName) });
		const idField = models[modelName].identifierFields[0];
		const choices = data?.[0]?.dropdownItem;
		const choice = Array.isArray(choices)
			? choices.find(item => item.value === field.value)
			: '';
		return <Typography>{choice?.[idField] || field.value}</Typography>;
	},
	format: ({ field, record }) => {
		return _.startCase(record[field]);
	},
	queryParameter: ({ name }) => name,
	convertToForm(value) {
		if (!value) return null;
		return value;
	}
};
