import React from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { ModelForm } from '../components';

export default function Dropdown({ formMode }) {
	const { id } = useParams();
	return (
		<Grid container>
			<ModelForm
				recordId={id}
				formName={'createDropdownForm'}
				initialMode={formMode}
			/>
		</Grid>
	);
}
