import parcelFieldType from '../../shared/field-types/parcel';
import id from './id';
import React from 'react';

const idType = 'parcel';

export default {
	...parcelFieldType,
	component: function parcel(props) {
		return <id.component {...props} idType={idType} />;
	},
	format: props => {
		return id.format({ ...props, idType });
	},
	queryParameter: props => {
		return id.queryParameter({ ...props, idType });
	},
	readOnlyComponent: function parcel(props) {
		return <id.readOnlyComponent {...props} idType={idType} />;
	}
};
