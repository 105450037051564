import React from 'react';
import id from './id';
import storeFieldType from '../../shared/field-types/store';

export default {
	...storeFieldType,
	component: function store(props) {
		return <id.component {...props} idType="store" />;
	},
	format: props => {
		return id.format({ ...props, idType: 'store' });
	},
	queryParameter: props => {
		return id.queryParameter({ ...props, idType: 'store' });
	},
	readOnlyComponent: function store(props) {
		return <id.readOnlyComponent {...props} idType="store" />;
	}
};
