import { LinearProgress } from '@material-ui/core';
import { useLayoutEffect, useState, useRef } from 'react';
import api from '../api';
import React from 'react';

function LoadingInterceptor() {
	const loadingInterceptor = useRef(null);
	const requestCounter = useRef(0);
	const [showLoading, setShowLoading] = useState(false);

	const addLoadingInterceptors = () => {
		const requestInterceptor = api.interceptors.request.use(request => {
			requestCounter.current++;
			if (requestCounter.current === 1) setShowLoading(true); // show loading only on the first request
			return request;
		});
		const responseInterceptor = api.interceptors.response.use(
			res => {
				requestCounter.current--;
				if (requestCounter.current === 0) setShowLoading(false);
				return res;
			},
			err => {
				requestCounter.current--;
				if (requestCounter.current === 0) setShowLoading(false);
				return Promise.reject(err);
			}
		);
		loadingInterceptor.current = {
			requestInterceptor,
			responseInterceptor
		};
	};

	const removeLoadingInterceptors = () => {
		if (loadingInterceptor.current) {
			api.interceptors.request.eject(
				loadingInterceptor.current.requestInterceptor
			);
			api.interceptors.response.eject(
				loadingInterceptor.current.responseInterceptor
			);
			loadingInterceptor.current = null;
		}
	};

	useLayoutEffect(() => {
		addLoadingInterceptors();
		return () => {
			removeLoadingInterceptors();
		};
	}, []);

	return (
		<LinearProgress
			color="secondary"
			style={{ visibility: showLoading ? 'visible' : 'hidden' }}
		/>
	);
}

export default LoadingInterceptor;
