const modelName = 'user';

export default {
	model: modelName,
	elements: [
		{
			field: 'username'
		},
		{
			field: 'role'
		},
		{
			field: 'firstName'
		},
		{
			field: 'lastName'
		},
		{
			field: 'email'
		},
		{
			field: 'phoneNumber'
		},
		{
			field: 'receiveEtaNotifications'
		},
		{
			field: 'storeIds'
		},
		{
			field: 'address'
		},
		{
			field: 'joinDate'
		}
	]
};
