import React from 'react';
import _ from 'lodash';
import { AppContextConsumer } from '../contexts/appContext';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import fieldTypes from '../field-types';

const styles = theme => ({
	field: {
		marginLeft: theme.spacing(1),
		'& .MuiInputBase-root': {
			width: '220px'
		}
	},
	label: {
		margin: theme.spacing(2),
		textAlign: 'left',
		verticalAlign: 'middle',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'left'
		}
	},
	readOnlyText: {
		'& .MuiTypography-root': {
			margin: theme.spacing(2),
			textAlign: 'left',
			verticalAlign: 'middle',
			[theme.breakpoints.down('sm')]: {
				textAlign: 'left'
			}
		}
	}
});

class FormField extends React.Component {
	dependentFieldsUpdated(nextProps) {
		const currentProps = this.props;
		let dependentFields = [];
		if (currentProps.dependentFields) {
			dependentFields = dependentFields.concat(currentProps.dependentFields);
		}
		if (currentProps.compute) {
			dependentFields = dependentFields.concat(
				currentProps.compute.dependentFields
			);
		}
		const currentValues = currentProps.form.values;
		const nextValues = nextProps.form.values;
		const currentDependentValues = _.pick(currentValues, dependentFields);
		const nextDependentValues = _.pick(nextValues, dependentFields);
		if (!_.isEqual(currentDependentValues, nextDependentValues)) return true;
	}

	shouldComponentUpdate(nextProps) {
		const currentProps = this.props;
		const fieldName = currentProps.field.name?.split('.');
		if (currentProps.field.value !== nextProps.field.value) return true;
		if (currentProps.readOnly !== nextProps.readOnly) return true;
		if (currentProps.form.isSubmitting !== nextProps.form.isSubmitting)
			return true;
		const currentError = _.get(this, ['props', 'form', 'errors', ...fieldName]);
		const nextError = _.get(nextProps, ['form', 'errors', ...fieldName]);
		if (currentError !== nextError) return true;
		if (this.dependentFieldsUpdated(nextProps)) return true;
		return false;
	}
	componentDidUpdate() {
		const { form, compute, field, record, config } = this.props;
		const { values, touched, setFieldValue, isSubmitting } = form;
		let dependentFields, touchedDependents;
		if (compute) {
			dependentFields = _.pick(values, compute.dependentFields);
			touchedDependents = _.pick(touched, compute.dependentFields);
		}
		/** @returns Returns true if all the dependent fields have touched OR have values (computed or in edit mode), else false. */
		const isDependentsTouched = () => {
			for (const key in dependentFields) {
				if (!touchedDependents[key] && !values[key]) return false;
			}
			return true;
		};

		if (compute) {
			if (isDependentsTouched() && !isSubmitting) {
				setFieldValue(field.name, compute.formulate(record, config));
			}
		}
	}

	render() {
		const {
			fieldWidth,
			type,
			record,
			hideLabel,
			readOnly,
			label,
			classes,
			...props
		} = this.props;
		const { field } = props;

		const fieldType = fieldTypes[type] || fieldTypes['textbox'];
		return (
			<AppContextConsumer>
				{appData => (
					<Grid container className={classes.field}>
						{hideLabel ? null : (
							<Grid container item xs={3}>
								<FormLabel className={classes.label} htmlFor={field.name}>
									{label}:
								</FormLabel>
							</Grid>
						)}
						{readOnly ? (
							fieldType.readOnlyComponent ? (
								<Grid item xs className={classes.readOnlyText}>
									<fieldType.readOnlyComponent
										record={record}
										models={appData.models}
										currencies={appData.currencies}
										{...props}
									/>
								</Grid>
							) : (
								<Grid item xs className={classes.readOnlyText}>
									<Typography>
										{fieldType.format({
											field: field.name,
											record,
											models: appData.models,
											currencies: appData.currencies
										}) || field.value}
									</Typography>
								</Grid>
							)
						) : (
							<Grid item xs={fieldWidth ? fieldWidth : 'auto'}>
								<fieldType.component {...props} record={record} />
							</Grid>
						)}
					</Grid>
				)}
			</AppContextConsumer>
		);
	}
}
export default withStyles(styles)(FormField);
