export default {
	model: 'parcel',
	elements: [
		{
			field: 'name'
		},
		{
			field: 'number',
			display: ({ values, mode }) => {
				//view mode only
				if (values && values.id && mode === 'view') return true;
				return false;
			}
		},
		{
			field: 'minCarat'
		},
		{
			field: 'maxCarat'
		},
		{
			field: 'caratRange',
			display: ({ values, mode }) => {
				//view mode only
				if (values && values.id && mode === 'view') return true;
				return false;
			}
		},
		{
			field: 'quantity'
		},
		{
			field: 'carat'
		},
		{
			field: 'cut'
		},
		{
			field: 'costPerCarat'
		},
		{
			field: 'pricePerCarat'
		},
		{
			field: 'installationCost'
		}
	]
};
