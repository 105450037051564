import React from 'react';
import id from './id';
import itemFieldType from '../../shared/field-types/item';

const idType = 'item';
export default {
	...itemFieldType,
	component: function item({ ...props }) {
		return <id.component {...props} idType={idType} />;
	},
	format: props => {
		return id.format({ ...props, idType });
	},
	queryParameter: props => {
		const idType = props.record?.partyType.toLowerCase();
		return id.queryParameter({ ...props, idType });
	},
	readOnlyComponent: function item(props) {
		return <id.readOnlyComponent {...props} idType={idType} />;
	}
};
