import React from 'react';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import TableCell from '@material-ui/core/TableCell';
import { Field } from 'formik';
import { FormField } from '..';

const useStyles = makeStyles(() => ({
	field: {
		maxWidth: '180px',
		minWidth: '120px',
		'& .MuiInputBase-root': {
			width: '100%'
		}
	}
}));

function InvoiceTableCell({ field, lineIndex, invoiceLineOptions, ...props }) {
	const { record } = props;
	const classes = useStyles();
	const { invoiceLinesField } = invoiceLineOptions;
	const name = `${invoiceLinesField}.${lineIndex}.${field}`;
	const value = get(record, name);
	if (props.compute) {
		let [...dependentFields] = props.compute.dependentFields;
		dependentFields = dependentFields.map(
			field => `${invoiceLinesField}.${lineIndex}.${field}`
		);
		props.compute = {
			...props.compute,
			dependentFields
		};
	}
	return (
		<TableCell>
			<Field
				classes={classes}
				name={`${invoiceLinesField}.${lineIndex}.${field}`}
				hideLabel={true}
				value={value}
				{...props}
				record={get(record, `${invoiceLinesField}.${lineIndex}`)}
				fieldWidth={12}
				component={FormField}
			/>
		</TableCell>
	);
}
export default InvoiceTableCell;
