import axios from 'axios';
import React from 'react';

const actions = {
	REQUEST_START: 'REQUEST_START',
	REQUEST_END: 'REQUEST_END'
};

const host = window.location.origin;
const api = axios.create({
	baseURL: host,
	xsrfHeaderName: 'x-csrf-token'
});

function isReactEvent(obj) {
	return obj && obj.nativeEvent && obj.nativeEvent instanceof Event;
}

function reducer(state, action) {
	switch (action.type) {
		case actions.REQUEST_START:
			return {
				...state,
				loading: true,
				error: null
			};
		case actions.REQUEST_END:
			return {
				...state,
				loading: false,
				...(action.error ? {} : { data: action.payload.data }),
				[action.error ? 'error' : 'response']: action.payload
			};
	}
}

const request = (options, dispatch) => {
	dispatch({ type: 'REQUEST_START' });
	return api
		.request({ ...options })
		.then(res => {
			dispatch({ type: 'REQUEST_END', payload: res });
		})
		.catch(err => {
			if (!axios.isCancel(err))
				dispatch({ type: 'REQUEST_END', payload: err, error: true });
		});
};

function useFetch(options) {
	const cancelSourceRef = React.useRef();

	const [state, dispatch] = React.useReducer(reducer, {
		loading: false,
		error: null,
		data: null
	});

	const cancelOutstandingRequest = React.useCallback(() => {
		if (cancelSourceRef.current) {
			cancelSourceRef.current.cancel();
		}
	}, []);

	options = React.useMemo(() => ({ manual: false, ...options }), [
		JSON.stringify(options)
	]);

	const withCancelToken = React.useCallback(
		options => {
			cancelOutstandingRequest();

			cancelSourceRef.current = axios.CancelToken.source();

			options.cancelToken = cancelSourceRef.current.token;

			return options;
		},
		[cancelOutstandingRequest]
	);

	React.useEffect(() => {
		if (!options.manual) request(withCancelToken(options), dispatch);
		return () => {
			if (cancelSourceRef.current) cancelSourceRef.current.cancel();
		};
	}, [options, cancelOutstandingRequest, withCancelToken]);

	const refetch = React.useCallback(
		optionsOverride => {
			return request(
				withCancelToken({
					...options,
					...(isReactEvent(optionsOverride) ? null : optionsOverride)
				}),
				dispatch
			);
		},
		[options, withCancelToken]
	);
	return [state, refetch];
}
export default api;
export { useFetch };
