import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import BillTableRow from './BillTableRow';

const useStyles = makeStyles(theme => ({
	addRowLink: {
		margin: theme.spacing(2)
	},
	totalTable: {
		width: '70%',
		'& td': {
			'border-bottom-width': '0px'
		}
	}
}));

function BillTable({ arrayHelpers, ...props }) {
	const { billLineOptions, columns, readOnly } = props;
	const { billLinesField } = billLineOptions;
	const billLines = arrayHelpers.form.values[billLinesField];
	const classes = useStyles();
	return (
		<>
			<Table aria-label="bill table">
				<TableHead>
					<TableRow>
						{columns.map((column, index) => (
							<TableCell key={index}>{column.label}</TableCell>
						))}

						{/* column for the delete icon */}
						{!readOnly ? <TableCell /> : null}
					</TableRow>
				</TableHead>
				<TableBody>
					{billLines.map((billLine, lineIndex) => (
						<BillTableRow
							key={lineIndex}
							billLine={billLine}
							record={arrayHelpers.form.values}
							lineIndex={lineIndex}
							deleteCell={
								!readOnly ? (
									<TableCell>
										<IconButton
											color="secondary"
											onClick={() => {
												arrayHelpers.remove(lineIndex);
											}}
										>
											<DeleteIcon fontSize="small" />
										</IconButton>
									</TableCell>
								) : null
							}
							{...props}
						/>
					))}
				</TableBody>
			</Table>
			<Grid container justify="space-between">
				<Grid item xs={3}>
					<div className={classes.addRowLink}>
						{!readOnly ? (
							<Button
								color="primary"
								onClick={() => {
									arrayHelpers.push({
										expenseCategory: 'product',
										itemId: '',
										price: 0,
										quantity: 1,
										amount: 0
									});
								}}
								startIcon={<AddCircleOutlineIcon />}
							>
								Add Line
							</Button>
						) : null}
					</div>
				</Grid>

				<Grid item xs={2}>
					<Table className={classes.totalTable}>
						<TableHead></TableHead>
						<TableBody>
							<TableRow>
								<TableCell scope="row">Total:</TableCell>
								<TableCell scope="row">
									<Typography>
										{billLines.reduce((a, b) => a + b.amount, 0)}
									</Typography>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		</>
	);
}

export default BillTable;
