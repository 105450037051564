import React from 'react';
import { each, find } from 'lodash';
import { TextField, Typography } from '@material-ui/core';
import { fieldToTextField } from 'formik-material-ui';
import { fieldToAutocomplete } from 'formik-material-ui-lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import api, { useFetch } from '../api';
import { useAppData } from '../contexts/appContext';
import idFieldType from '../../shared/field-types/id';

function setSearchParams(filters = {}, record, formValues, url) {
	each(filters, (recordField, modelField) => {
		if (recordField.constant) {
			url.searchParams.set(modelField, recordField.value);
		} else {
			url.searchParams.set(
				modelField,
				record[recordField.value] || formValues[recordField.value]
			);
		}
	});
}

export default {
	...idFieldType,
	component: function id({
		idType,
		label,
		filters,
		customLabelField,
		...props
	}) {
		const { field, form } = props;
		const baseUrl = new URL(`api/${idType}`, api.defaults.baseURL);
		setSearchParams(filters, props.record, form.values, baseUrl);
		const [{ data }] = useFetch({ url: baseUrl });
		const appData = useAppData();
		const idField = appData ? appData.models[idType].identifierFields[0] : '';
		const autoCompleteProps = fieldToAutocomplete({ field, form });
		autoCompleteProps.onChange = (_, value) => {
			form.setFieldValue(field.name, value?.id || null);
		};
		const textFieldProps = fieldToTextField({ form, field });
		return (
			<Autocomplete
				{...autoCompleteProps}
				options={data || []}
				value={
					data && field.value
						? (find(data, item => item.id === field.value) || {}).id
						: null
				}
				getOptionLabel={option => {
					return (
						(option[customLabelField || idField] ||
							(find(data, item => item.id === option) || {})[
								customLabelField || idField
							] ||
							'') + ''
					);
				}}
				getOptionSelected={(item, current) => {
					return current === '' ? true : item.id === current;
				}}
				renderInput={params => (
					<TextField
						{...params}
						label={label}
						error={textFieldProps.error}
						helperText={textFieldProps.helperText}
					/>
				)}
			/>
		);
	},
	format: props => {
		return formatData(props);
	},
	queryParameter: ({ models, idType }) => {
		return `parentRelation_${idType}.${models[idType].identifierFields[0]}`;
	},
	readOnlyComponent: function id(props) {
		return <Typography>{formatData(props)}</Typography>;
	}
};
const formatData = ({ field, record, models, idType }) => {
	if (models) return record[`${idType}_${models[idType].identifierFields[0]}`];
	return record[field];
};
