import React from 'react';
import AccountsTable from '../components/AccountsTable';

const modelName = 'vendor';

export default {
	model: modelName,
	elements: [
		{
			field: 'name'
		},
		{
			field: 'phoneNumber'
		},
		{
			field: 'email'
		},
		{
			field: 'registrationNumber'
		},
		{
			field: 'streetNumber'
		},
		{
			field: 'street'
		},
		{
			field: 'city'
		},
		{
			field: 'governorate'
		},
		{
			field: 'country'
		},
		{
			field: 'contactName'
		},
		{
			field: 'contantPhoneNumber'
		},
		{
			field: 'venderAbbreviation'
		},
		{
			component: function accounts({ record, model }) {
				return <AccountsTable record={record} parentType={model} />;
			},
			display: ({ mode, ...values }) => {
				//  hides component when 'create' or 'edit' mode is applied
				if ((mode === 'view' && !values?.id) || mode !== 'view') return false;
				return true;
			}
		}
	]
};
