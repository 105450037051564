import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { fieldToAutocomplete } from 'formik-material-ui-lab';
import { fieldToTextField } from 'formik-material-ui';
import api from '../api';
import { useAppData } from '../contexts/appContext';
import idsFieldType from '../../shared/field-types/ids';

const parseMatched = (text, matched) => {
	let result = [];
	const [startIndex, endIndex] = matched;

	if (_.isEmpty(matched)) {
		result.push({ text: text, highlight: false });
	} else {
		if (startIndex > 0) {
			result.push({ text: text.slice(0, startIndex), highlight: false });
		}
		result.push({ text: text.slice(startIndex, endIndex), highlight: true });
		if (endIndex < text.length) {
			result.push({
				text: text.slice(endIndex, text.length),
				highlight: false
			});
		}
	}
	return result;
};

function setSearchParams(filters = {}, record, formValues, url) {
	_.each(filters, (recordField, modelField) => {
		if (recordField.constant) {
			url.searchParams.set(modelField, recordField.value);
		} else {
			url.searchParams.set(
				modelField,
				record[recordField.value] || formValues[recordField.value]
			);
		}
	});
}

export default {
	...idsFieldType,
	component: function ids({ idsMapper, filters, ...props }) {
		const { model, mode, form } = props;
		const baseModelUrl = new URL(`api/${model}`, api.defaults.baseURL);
		setSearchParams(filters, props.record, form.values, baseModelUrl);
		const [autocompleteValue, setAutocompleteValue] = React.useState([]);

		const appData = useAppData();
		const idField = appData?.models[model].identifierFields[0];
		const {
			field,
			form: { values, setFieldValue }
		} = props;

		const [open, setOpen] = useState(false);
		const [options, setOptions] = useState([]);
		const loading = open && options?.length === 0;
		const autoCompleteProps = fieldToAutocomplete({ field, form });
		const textFieldProps = fieldToTextField({ field, form });
		useEffect(() => {
			if (mode === 'edit' && values[idsMapper]) {
				setAutocompleteValue(values[idsMapper]);
			}
		}, [mode, values[idsMapper]]);

		useEffect(() => {
			let active = true;
			if (!loading) return undefined;

			api.get(baseModelUrl).then(response => {
				const values = response.data;
				if (active) {
					if (values?.length) setOptions(values);
					else setOptions(null);
				}
			});

			return () => {
				active = false;
			};
		}, [loading]);

		useEffect(() => {
			if (!open) setOptions([]);
		}, [open]);

		return (
			<Autocomplete
				className={props.className}
				{...autoCompleteProps}
				multiple
				id={field.name}
				name={field.name}
				value={autocompleteValue}
				open={open}
				onOpen={() => setOpen(true)}
				onClose={() => setOpen(false)}
				options={options || []}
				loading={loading}
				filterSelectedOptions
				getOptionLabel={option => {
					return (
						option[idField] ||
						(_.find(options, item => item.id === option) || {})[idField] ||
						''
					);
				}}
				getOptionSelected={(option, value) => {
					return option.id === value.id;
				}}
				onChange={(_event, value) => {
					const valuesArray = value.map(v => v.id);
					setFieldValue(field.name, valuesArray);
					setFieldValue(idsMapper, value);
					setAutocompleteValue(value);
				}}
				renderInput={params => (
					<TextField
						{...params}
						error={textFieldProps.error}
						helperText={textFieldProps.helperText}
						margin="normal"
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading && <CircularProgress size={20} />}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
					/>
				)}
				renderOption={(option, { inputValue }) => {
					const valueIndex = option[idField].toLowerCase().indexOf(inputValue);
					const matches = inputValue
						? [valueIndex, valueIndex + inputValue.length]
						: [];
					const parts = parseMatched(option[idField], matches);
					return (
						<div>
							{parts.map((part, index) => (
								<span
									key={index}
									style={{
										fontWeight: part.highlight ? 700 : 400
									}}
								>
									{part.text}
								</span>
							))}
						</div>
					);
				}}
			/>
		);
	},
	format: props => formatData(props)
};

const formatData = ({ record, models, model, idsMapper }) => {
	if (!record[idsMapper]) return '';
	const parsedValues = record[idsMapper]?.map(fieldValue => {
		return fieldValue[models[model].identifierFields[0]];
	});
	return models ? parsedValues?.join(', ') : record[idsMapper];
};
