const modelName = 'dropdownitem';

export default {
	model: modelName,
	elements: [
		{
			field: 'dropdownId',
			display: () => false
		},
		{
			field: 'name'
		},
		{
			field: 'value',
			editable: () => false
		},
		{
			field: 'static'
		},
		{
			field: 'rank'
		}
	]
};
