const _ = require('lodash');

module.exports = {
	value: {
		dependentFields: ['name'],
		formulate: ({ name }) => {
			return _.snakeCase(name);
		}
	}
};
