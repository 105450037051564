import { useContext, createContext } from 'react';
import sharedModels from '../../shared/models';
import layouts from '../layouts';

const AppContext = createContext({});
const useAppData = () => useContext(AppContext);
const AppContextProvider = AppContext.Provider;
const AppContextConsumer = AppContext.Consumer;
const createContextData = (data = {}) => {
	const { models = {} } = data;
	Object.keys(models).forEach(modelKey => {
		const model = models[modelKey];
		const sharedModel = sharedModels[modelKey] || {};
		Object.keys(sharedModel).forEach(propKey => {
			if (model[propKey] == null) model[propKey] = sharedModel[propKey];
		});
	});
	data.layouts = layouts;
	return data;
};

export {
	AppContext,
	AppContextProvider,
	AppContextConsumer,
	useAppData,
	createContextData
};
