const modelName = 'item';

export default {
	model: modelName,
	elements: [
		{
			field: 'itemCode'
		},
		{
			field: 'description'
		},
		{
			field: 'category',
			editable: values => {
				//On creation
				if (values.id) return false;
				return true;
			}
		},
		{
			field: 'goldKarat',
			display: ({ values }) => {
				if (['gold', 'diamond', 'mountings'].includes(values.category))
					return true;
				return false;
			}
		},
		{
			field: 'type',
			editable: values => {
				//On creation
				if (values.id) return false;
				return true;
			}
		},
		{
			field: 'goldWeight',
			editable: values => {
				//On creation
				if (values.id) return false;
				return true;
			}
		}
	]
};
